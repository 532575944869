import styled from 'styled-components';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PrimaryButton, NotificationController} from '@imperva/basic-components';
import {Field, Form, TextInput as TextInputOrigin, useSubmitForm, validators} from '@imperva/form';
import {MFA_TYPES} from '../../../../shared/constants';
import {history} from '../../../../utils';
import {CountryCodeDropdown} from './CountryCodeDropdown';
const {validateAll, phoneNumber, stringMaxLength} = validators;


const Wrapper = styled.div`
	align-items: flex-start;
	display: flex;
    justify-content: space-between;
`;

export const FormGroup = styled.div`
  align-items: flex-start;
  display: flex;
  padding-bottom: 20px;
`;

export const FieldsGroup = styled.div`
	display: flex;
	margin-right: 15px;
	width: 360px;
`;

export const AlignedToFieldsButtons = styled(PrimaryButton)`
	margin-top: 23px;
`;

const PhoneInput = styled(TextInputOrigin)`
	left:5px;
	margin-top: 22px;
	position:relative;
	width: 254px;
`;

const PhoneWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	height: 64px;
	margin-bottom: 5px;
`;

const initState = {label:'+', value:'+'};

export const PhoneEnroll = ({factorName, factorData, setTransaction, setPrevFunc, setVerifyButtonDisabled}) => {
	const {t} = useTranslation();
	const [countryCode, setCountryCode] = useState(initState);
	const [getCodeButtonDisabled, setGetCodeButtonDisabled] = useState(false);

	useEffect(() => {
		if (!factorData.enroll) {
			history.push('/login');
			return;
		}
	},[factorData]);

	const getCodeHandler = useCallback(async(phoneNumber, onFailure, onSuccess) => {
		if (!factorData.enroll) {
			history.push('/login');
			return;
		}

		try {
			const payload = await factorData.enroll({
				profile: {
					phoneNumber: countryCode.value + phoneNumber,
					updatePhone: true
				}
			});
			setTransaction(payload);
			setPrevFunc(() => payload.prev);
			onSuccess();
		} catch (err) {
			onFailure(err);
		};

	}, [factorData, setPrevFunc, setTransaction, countryCode]);

	const submitGetCodeCb = ({factorPhoneNumber}, meta) => {
		getCodeHandler(factorPhoneNumber, meta.callbacks.failure, meta.callbacks.success);
	};

	const {submitForm, submitting} = useSubmitForm(submitGetCodeCb);

	const handleGetCodeSuccess = useCallback((setSubmitting) => {
		setSubmitting(false);
		setGetCodeButtonDisabled(true);
		setVerifyButtonDisabled(false);
		const desc = factorData.simpleType === MFA_TYPES.call ? t('mfa.enrollForm.phone.phoneSuccess') :
			t('mfa.enrollForm.phone.smsSuccess');
		NotificationController.info(factorName + ' validation', {subtitle: desc, container: 'box', position: 'top-center'});
	},[factorData.simpleType, factorName, setVerifyButtonDisabled, t]);

	const handleGetCodeFailure = useCallback((setSubmitting, err) => {
		setSubmitting(false);
		const desc = t('mfa.enrollForm.phone.error');
		NotificationController.error(factorName + ' validation', {subtitle: desc, container: 'box', position: 'top-center'});
		console.log('Error: ', err);
	},[t, factorName]);

	const handleSubmitGetCode = useCallback((values, {setSubmitting, setFieldError}) => {
		submitForm({
			values,
			onSuccess: () => handleGetCodeSuccess(setSubmitting),
			onFailure: (err) => handleGetCodeFailure(setSubmitting, err),
		});
	},[handleGetCodeFailure, handleGetCodeSuccess, submitForm]);

	return (
		<Wrapper>
			<Form
				initialValues={{
					factorPhoneNumber: '',
				}}
				onSubmit={handleSubmitGetCode}
				isSettingsPage={false}
			>
				{({isValid, dirty})=>(
					<FormGroup>
						<FieldsGroup>
							<PhoneWrapper>
								<CountryCodeDropdown
									countryCode={countryCode}
									setCountryCode={setCountryCode}
								/>
								<Field
									id="factorPhoneNumber"
									name="factorPhoneNumber"
									component={PhoneInput}
									labelText={''}
									validate={validateAll(phoneNumber('Invalid phone number'), stringMaxLength(20, 'Number is too long'))}
								/>
							</PhoneWrapper>
						</FieldsGroup>
						<AlignedToFieldsButtons
							type="submit"
							disabled={getCodeButtonDisabled || !isValid || !dirty || countryCode === initState || submitting}
						>
							{t('mfa.enrollForm.phone.getCodeButton')}
						</AlignedToFieldsButtons>
					</FormGroup>
				)}
			</Form>
		</Wrapper>
	);
};




