import * as yup from 'yup';

export const resetPasswordSchema = yup.object().shape({
	newPassword: yup.string()
		.required('passwordAssistant.errors.newPassword.required')
		.min(12, 'passwordAssistant.errors.newPassword.min')
		.matches(/\d/ , 'passwordAssistant.errors.newPassword.number')
		.matches(/[a-z]+/, 'passwordAssistant.errors.newPassword.lowercase')
		.matches(/[A-Z]+/, 'passwordAssistant.errors.newPassword.uppercase')
		//eslint-disable-next-line
		.matches(/[-._!"`#%&,:;<>=@{}~$()*+\/\\?\[\]^|']+/, 'passwordAssistant.errors.newPassword.special'),
	confirmNewPassword: yup.string()
		.required('passwordAssistant.errors.confirmNewPassword.required')
		.oneOf([yup.ref('newPassword'), null], 'passwordAssistant.errors.confirmNewPassword.match')
});

export const changePasswordSchema = yup.object().shape({
	oldPassword: yup.string().required('passwordAssistant.errors.oldPasswordRequired'),
	newPassword: yup.string()
		.required('passwordAssistant.errors.newPassword.required')
		.min(12, 'passwordAssistant.errors.newPassword.min')
		.matches(/\d/ , 'passwordAssistant.errors.newPassword.number')
		.matches(/[a-z]+/, 'passwordAssistant.errors.newPassword.lowercase')
		.matches(/[A-Z]+/, 'passwordAssistant.errors.newPassword.uppercase')
		//eslint-disable-next-line
		.matches(/[-._!"`#%&,:;<>=@{}~$()*+\/\\?\[\]^|']+/, 'passwordAssistant.errors.newPassword.special'),
	confirmNewPassword: yup.string()
		.required('passwordAssistant.errors.confirmNewPassword.required')
		.oneOf([yup.ref('newPassword'), null], 'passwordAssistant.errors.confirmNewPassword.match')
});