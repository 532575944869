export const countryCodes = [
	{
		'country': 'Afghanistan',
		'unicode': 'af',
		'code': '93',
	},
	{
		'country': 'Albania',
		'unicode': 'al',
		'code': '355'
	},
	{
		'country': 'Algeria',
		'unicode': 'dz',
		'code': '213'
	},
	{
		'country': 'American Samoa',
		'unicode': 'as',
		'code': '1-684'
	},
	{
		'country': 'Andorra',
		'unicode': 'ad',
		'code': '376'
	},
	{
		'country': 'Angola',
		'unicode': 'ao',
		'code': '244'
	},
	{
		'country': 'Anguilla',
		'unicode': 'ai',
		'code': '1-264'
	},
	{
		'country': 'Antarctica',
		'unicode': 'aq',
		'code': '672'
	},
	{
		'country': 'Antigua and Barbuda',
		'unicode': 'ag',
		'code': '1-268'
	},
	{
		'country': 'Argentina',
		'unicode': 'ar',
		'code': '54'
	},
	{
		'country': 'Armenia',
		'unicode': 'am',
		'code': '374'
	},
	{
		'country': 'Aruba',
		'unicode': 'aw',
		'code': '297'
	},
	{
		'country': 'Australia',
		'unicode': 'au',
		'code': '61'
	},
	{
		'country': 'Austria',
		'unicode': 'at',
		'code': '43'
	},
	{
		'country': 'Azerbaijan',
		'unicode': 'az',
		'code': '994'
	},
	{
		'country': 'Bahamas',
		'unicode': 'bs',
		'code': '1-242'
	},
	{
		'country': 'Bahrain',
		'unicode': 'bh',
		'code': '973'
	},
	{
		'country': 'Bangladesh',
		'unicode': 'bd',
		'code': '880'
	},
	{
		'country': 'Barbados',
		'unicode': 'bb',
		'code': '1-246'
	},
	{
		'country': 'Belarus',
		'unicode': 'by',
		'code': '375'
	},
	{
		'country': 'Belgium',
		'unicode': 'be',
		'code': '32'
	},
	{
		'country': 'Belize',
		'unicode': 'bz',
		'code': '501'
	},
	{
		'country': 'Benin',
		'unicode': 'bj',
		'code': '229'
	},
	{
		'country': 'Bermuda',
		'unicode': 'bm',
		'code': '1-441'
	},
	{
		'country': 'Bhutan',
		'unicode': 'bt',
		'code': '975'
	},
	{
		'country': 'Bolivia',
		'unicode': 'bo',
		'code': '591'
	},
	{
		'country': 'Bosnia and Herzegovina',
		'unicode': 'ba',
		'code': '387'
	},
	{
		'country': 'Botswana',
		'unicode': 'bw',
		'code': '267'
	},
	{
		'country': 'Brazil',
		'unicode': 'br',
		'code': '55'
	},
	{
		'country': 'British Indian Ocean Territory',
		'unicode': 'io',
		'code': '246'
	},
	{
		'country': 'British Virgin Islands',
		'unicode': 'vg',
		'code': '1-284'
	},
	{
		'country': 'Brunei',
		'unicode': 'bn',
		'code': '673'
	},
	{
		'country': 'Bulgaria',
		'unicode': 'bg',
		'code': '359'
	},
	{
		'country': 'Burkina Faso',
		'unicode': 'bf',
		'code': '226'
	},
	{
		'country': 'Burundi',
		'unicode': 'bi',
		'code': '257'
	},
	{
		'country': 'Cambodia',
		'unicode': 'kh',
		'code': '855'
	},
	{
		'country': 'Cameroon',
		'unicode': 'cm',
		'code': '237'
	},
	{
		'country': 'Canada',
		'unicode': 'ca',
		'code': '1'
	},
	{
		'country': 'Cape Verde',
		'unicode': 'cv',
		'code': '238'
	},
	{
		'country': 'Cayman Islands',
		'unicode': 'ky',
		'code': '1-345'
	},
	{
		'country': 'Central African Republic',
		'unicode': 'cf',
		'code': '236'
	},
	{
		'country': 'Chad',
		'unicode': 'td',
		'code': '235'
	},
	{
		'country': 'Chile',
		'unicode': 'cl',
		'code': '56'
	},
	{
		'country': 'China',
		'unicode': 'cn',
		'code': '86'
	},
	{
		'country': 'Christmas Island',
		'unicode': 'cx',
		'code': '61'
	},
	{
		'country': 'Cocos Islands',
		'unicode': 'cc',
		'code': '61'
	},
	{
		'country': 'Colombia',
		'unicode': 'co',
		'code': '57'
	},
	{
		'country': 'Comoros',
		'unicode': 'km',
		'code': '269'
	},
	{
		'country': 'Cook Islands',
		'unicode': 'ck',
		'code': '682'
	},
	{
		'country': 'Costa Rica',
		'unicode': 'cr',
		'code': '506'
	},
	{
		'country': 'Croatia',
		'unicode': 'hr',
		'code': '385'
	},
	{
		'country': 'Cuba',
		'unicode': 'cu',
		'code': '53'
	},
	{
		'country': 'Curacao',
		'unicode': 'cw',
		'code': '599'
	},
	{
		'country': 'Cyprus',
		'unicode': 'cy',
		'code': '357'
	},
	{
		'country': 'Czech Republic',
		'unicode': 'cz',
		'code': '420'
	},
	{
		'country': 'Democratic Republic of the Congo',
		'unicode': 'cd',
		'code': '243'
	},
	{
		'country': 'Denmark',
		'unicode': 'dk',
		'code': '45'
	},
	{
		'country': 'Djibouti',
		'unicode': 'dj',
		'code': '253'
	},
	{
		'country': 'Dominica',
		'unicode': 'dm',
		'code': '1-767'
	},
	{
		'country': 'Dominican Republic',
		'unicode': 'do',
		'code': '1-809'
	},
	{
		'country': 'Dominican Republic',
		'unicode': 'do',
		'code': '1-829'
	},
	{
		'country': 'Dominican Republic',
		'unicode': 'do',
		'code': '1-849'
	},
	{
		'country': 'East Timor',
		'unicode': 'tl',
		'code': '670'
	},
	{
		'country': 'Ecuador',
		'unicode': 'ec',
		'code': '593'
	},
	{
		'country': 'Egypt',
		'unicode': 'eg',
		'code': '20'
	},
	{
		'country': 'El Salvador',
		'unicode': 'sv',
		'code': '503'
	},
	{
		'country': 'Equatorial Guinea',
		'unicode': 'gq',
		'code': '240'
	},
	{
		'country': 'Eritrea',
		'unicode': 'er',
		'code': '291'
	},
	{
		'country': 'Estonia',
		'unicode': 'ee',
		'code': '372'
	},
	{
		'country': 'Ethiopia',
		'unicode': 'et',
		'code': '251'
	},
	{
		'country': 'Falkland Islands',
		'unicode': 'fk',
		'code': '500'
	},
	{
		'country': 'Faroe Islands',
		'unicode': 'fo',
		'code': '298'
	},
	{
		'country': 'Fiji',
		'unicode': 'fj',
		'code': '679'
	},
	{
		'country': 'Finland',
		'unicode': 'fi',
		'code': '358'
	},
	{
		'country': 'France',
		'unicode': 'fr',
		'code': '33'
	},
	{
		'country': 'French Polynesia',
		'unicode': 'pf',
		'code': '689'
	},
	{
		'country': 'Gabon',
		'unicode': 'ga',
		'code': '241'
	},
	{
		'country': 'Gambia',
		'unicode': 'gm',
		'code': '220'
	},
	{
		'country': 'Georgia',
		'unicode': 'ge',
		'code': '995'
	},
	{
		'country': 'Germany',
		'unicode': 'de',
		'code': '49'
	},
	{
		'country': 'Ghana',
		'unicode': 'gh',
		'code': '233'
	},
	{
		'country': 'Gibraltar',
		'unicode': 'gi',
		'code': '350'
	},
	{
		'country': 'Greece',
		'unicode': 'gr',
		'code': '30'
	},
	{
		'country': 'Greenland',
		'unicode': 'gl',
		'code': '299'
	},
	{
		'country': 'Grenada',
		'unicode': 'gd',
		'code': '1-473'
	},
	{
		'country': 'Guam',
		'unicode': 'gu',
		'code': '1-671'
	},
	{
		'country': 'Guatemala',
		'unicode': 'gt',
		'code': '502'
	},
	{
		'country': 'Guernsey',
		'unicode': 'gg',
		'code': '44-1481'
	},
	{
		'country': 'Guinea',
		'unicode': 'gn',
		'code': '224'
	},
	{
		'country': 'Guinea-Bissau',
		'unicode': 'gw',
		'code': '245'
	},
	{
		'country': 'Guyana',
		'unicode': 'gy',
		'code': '592'
	},
	{
		'country': 'Haiti',
		'unicode': 'ht',
		'code': '509'
	},
	{
		'country': 'Honduras',
		'unicode': 'hn',
		'code': '504'
	},
	{
		'country': 'Hong Kong',
		'unicode': 'hk',
		'code': '852'
	},
	{
		'country': 'Hungary',
		'unicode': 'hu',
		'code': '36'
	},
	{
		'country': 'Iceland',
		'unicode': 'is',
		'code': '354'
	},
	{
		'country': 'India',
		'unicode': 'in',
		'code': '91'
	},
	{
		'country': 'Indonesia',
		'unicode': 'id',
		'code': '62'
	},
	{
		'country': 'Iran',
		'unicode': 'ir',
		'code': '98'
	},
	{
		'country': 'Iraq',
		'unicode': 'iq',
		'code': '964'
	},
	{
		'country': 'Ireland',
		'unicode': 'ie',
		'code': '353'
	},
	{
		'country': 'Isle of Man',
		'unicode': 'im',
		'code': '44-1624'
	},
	{
		'country': 'Israel',
		'unicode': 'il',
		'code': '972'
	},
	{
		'country': 'Italy',
		'unicode': 'it',
		'code': '39'
	},
	{
		'country': 'Ivory Coast',
		'unicode': 'ci',
		'code': '225'
	},
	{
		'country': 'Jamaica',
		'unicode': 'jm',
		'code': '1-876'
	},
	{
		'country': 'Japan',
		'unicode': 'jp',
		'code': '81'
	},
	{
		'country': 'Jersey',
		'unicode': 'je',
		'code': '44-1534'
	},
	{
		'country': 'Jordan',
		'unicode': 'jo',
		'code': '962'
	},
	{
		'country': 'Kazakhstan',
		'unicode': 'kz',
		'code': '7'
	},
	{
		'country': 'Kenya',
		'unicode': 'ke',
		'code': '254'
	},
	{
		'country': 'Kiribati',
		'unicode': 'ki',
		'code': '686'
	},
	{
		'country': 'Kosovo',
		'unicode': '',
		'code': '383'
	},
	{
		'country': 'Kuwait',
		'unicode': 'kw',
		'code': '965'
	},
	{
		'country': 'Kyrgyzstan',
		'unicode': 'kg',
		'code': '996'
	},
	{
		'country': 'Laos',
		'unicode': 'la',
		'code': '856'
	},
	{
		'country': 'Latvia',
		'unicode': 'lv',
		'code': '371'
	},
	{
		'country': 'Lebanon',
		'unicode': 'lb',
		'code': '961'
	},
	{
		'country': 'Lesotho',
		'unicode': 'ls',
		'code': '266'
	},
	{
		'country': 'Liberia',
		'unicode': 'lr',
		'code': '231'
	},
	{
		'country': 'Libya',
		'unicode': 'ly',
		'code': '218'
	},
	{
		'country': 'Liechtenstein',
		'unicode': 'li',
		'code': '423'
	},
	{
		'country': 'Lithuania',
		'unicode': 'lt',
		'code': '370'
	},
	{
		'country': 'Luxembourg',
		'unicode': 'lu',
		'code': '352'
	},
	{
		'country': 'Macau',
		'unicode': 'mo',
		'code': '853'
	},
	{
		'country': 'Macedonia',
		'unicode': 'mk',
		'code': '389'
	},
	{
		'country': 'Madagascar',
		'unicode': 'mg',
		'code': '261'
	},
	{
		'country': 'Malawi',
		'unicode': 'mw',
		'code': '265'
	},
	{
		'country': 'Malaysia',
		'unicode': 'my',
		'code': '60'
	},
	{
		'country': 'Maldives',
		'unicode': 'mv',
		'code': '960'
	},
	{
		'country': 'Mali',
		'unicode': 'ml',
		'code': '223'
	},
	{
		'country': 'Malta',
		'unicode': 'mt',
		'code': '356'
	},
	{
		'country': 'Marshall Islands',
		'unicode': 'mh',
		'code': '692'
	},
	{
		'country': 'Mauritania',
		'unicode': 'mr',
		'code': '222'
	},
	{
		'country': 'Mauritius',
		'unicode': 'mu',
		'code': '230'
	},
	{
		'country': 'Mayotte',
		'unicode': 'yt',
		'code': '262'
	},
	{
		'country': 'Mexico',
		'unicode': 'mx',
		'code': '52'
	},
	{
		'country': 'Micronesia',
		'unicode': 'fm',
		'code': '691'
	},
	{
		'country': 'Moldova',
		'unicode': 'md',
		'code': '373'
	},
	{
		'country': 'Monaco',
		'unicode': 'mc',
		'code': '377'
	},
	{
		'country': 'Mongolia',
		'unicode': 'mn',
		'code': '976'
	},
	{
		'country': 'Montenegro',
		'unicode': 'me',
		'code': '382'
	},
	{
		'country': 'Montserrat',
		'unicode': 'ms',
		'code': '1-664'
	},
	{
		'country': 'Morocco',
		'unicode': 'ma',
		'code': '212'
	},
	{
		'country': 'Mozambique',
		'unicode': 'mz',
		'code': '258'
	},
	{
		'country': 'Myanmar',
		'unicode': 'mm',
		'code': '95'
	},
	{
		'country': 'Namibia',
		'unicode': 'na',
		'code': '264'
	},
	{
		'country': 'Nauru',
		'unicode': 'nr',
		'code': '674'
	},
	{
		'country': 'Nepal',
		'unicode': 'np',
		'code': '977'
	},
	{
		'country': 'Netherlands',
		'unicode': 'nl',
		'code': '31'
	},
	{
		'country': 'Netherlands Antilles',
		'unicode': 'an',
		'code': '599'
	},
	{
		'country': 'New Caledonia',
		'unicode': 'nc',
		'code': '687'
	},
	{
		'country': 'New Zealand',
		'unicode': 'nz',
		'code': '64'
	},
	{
		'country': 'Nicaragua',
		'unicode': 'ni',
		'code': '505'
	},
	{
		'country': 'Niger',
		'unicode': 'ne',
		'code': '227'
	},
	{
		'country': 'Nigeria',
		'unicode': 'ng',
		'code': '234'
	},
	{
		'country': 'Niue',
		'unicode': 'nu',
		'code': '683'
	},
	{
		'country': 'North Korea',
		'unicode': 'kp',
		'code': '850'
	},
	{
		'country': 'Northern Mariana Islands',
		'unicode': 'mp',
		'code': '1-670'
	},
	{
		'country': 'Norway',
		'unicode': 'no',
		'code': '47'
	},
	{
		'country': 'Oman',
		'unicode': 'om',
		'code': '968'
	},
	{
		'country': 'Pakistan',
		'unicode': 'pk',
		'code': '92'
	},
	{
		'country': 'Palau',
		'unicode': 'pw',
		'code': '680'
	},
	{
		'country': 'Palestine',
		'unicode': 'ps',
		'code': '970'
	},
	{
		'country': 'Panama',
		'unicode': 'pa',
		'code': '507'
	},
	{
		'country': 'Papua New Guinea',
		'unicode': 'pg',
		'code': '675'
	},
	{
		'country': 'Paraguay',
		'unicode': 'py',
		'code': '595'
	},
	{
		'country': 'Peru',
		'unicode': 'pe',
		'code': '51'
	},
	{
		'country': 'Philippines',
		'unicode': 'ph',
		'code': '63'
	},
	{
		'country': 'Pitcairn',
		'unicode': 'pn',
		'code': '64'
	},
	{
		'country': 'Poland',
		'unicode': 'pl',
		'code': '48'
	},
	{
		'country': 'Portugal',
		'unicode': 'pt',
		'code': '351'
	},
	{
		'country': 'Puerto Rico',
		'unicode': 'pr',
		'code': '1-787, 1-939'
	},
	{
		'country': 'Qatar',
		'unicode': 'qa',
		'code': '974'
	},
	{
		'country': 'Republic of the Congo',
		'unicode': 'cg',
		'code': '242'
	},
	{
		'country': 'Reunion',
		'unicode': 're',
		'code': '262'
	},
	{
		'country': 'Romania',
		'unicode': 'ro',
		'code': '40'
	},
	{
		'country': 'Russia',
		'unicode': 'ru',
		'code': '7'
	},
	{
		'country': 'Rwanda',
		'unicode': 'rw',
		'code': '250'
	},
	{
		'country': 'Saint Barthelemy',
		'unicode': 'gp',
		'code': '590'
	},
	{
		'country': 'Saint Helena',
		'unicode': 'sh',
		'code': '290'
	},
	{
		'country': 'Saint Kitts and Nevis',
		'unicode': 'kn',
		'code': '1-869'
	},
	{
		'country': 'Saint Lucia',
		'unicode': 'lc',
		'code': '1-758'
	},
	{
		'country': 'Saint Martin',
		'unicode': 'gp',
		'code': '590'
	},
	{
		'country': 'Saint Pierre and Miquelon',
		'unicode': 'pm',
		'code': '508'
	},
	{
		'country': 'Saint Vincent and the Grenadines',
		'unicode': 'vc',
		'code': '1-784'
	},
	{
		'country': 'Samoa',
		'unicode': 'ws',
		'code': '685'
	},
	{
		'country': 'San Marino',
		'unicode': 'sm',
		'code': '378'
	},
	{
		'country': 'Sao Tome and Principe',
		'unicode': 'st',
		'code': '239'
	},
	{
		'country': 'Saudi Arabia',
		'unicode': 'sa',
		'code': '966'
	},
	{
		'country': 'Senegal',
		'unicode': 'sn',
		'code': '221'
	},
	{
		'country': 'Serbia',
		'unicode': 'rs',
		'code': '381'
	},
	{
		'country': 'Seychelles',
		'unicode': 'sc',
		'code': '248'
	},
	{
		'country': 'Sierra Leone',
		'unicode': 'sl',
		'code': '232'
	},
	{
		'country': 'Singapore',
		'unicode': 'sg',
		'code': '65'
	},
	{
		'country': 'Sint Maarten',
		'unicode': 'sx',
		'code': '1-721'
	},
	{
		'country': 'Slovakia',
		'unicode': 'sk',
		'code': '421'
	},
	{
		'country': 'Slovenia',
		'unicode': 'si',
		'code': '386'
	},
	{
		'country': 'Solomon Islands',
		'unicode': 'sb',
		'code': '677'
	},
	{
		'country': 'Somalia',
		'unicode': 'so',
		'code': '252'
	},
	{
		'country': 'South Africa',
		'unicode': 'za',
		'code': '27'
	},
	{
		'country': 'South Korea',
		'unicode': 'kr',
		'code': '82'
	},
	{
		'country': 'South Sudan',
		'unicode': 'ss',
		'code': '211'
	},
	{
		'country': 'Spain',
		'unicode': 'es',
		'code': '34'
	},
	{
		'country': 'Sri Lanka',
		'unicode': 'lk',
		'code': '94'
	},
	{
		'country': 'Sudan',
		'unicode': 'sd',
		'code': '249'
	},
	{
		'country': 'Suriname',
		'unicode': 'sr',
		'code': '597'
	},
	{
		'country': 'Svalbard and Jan Mayen',
		'unicode': 'sj',
		'code': '47'
	},
	{
		'country': 'Swaziland',
		'unicode': 'sz',
		'code': '268'
	},
	{
		'country': 'Sweden',
		'unicode': 'se',
		'code': '46'
	},
	{
		'country': 'Switzerland',
		'unicode': 'ch',
		'code': '41'
	},
	{
		'country': 'Syria',
		'unicode': 'sy',
		'code': '963'
	},
	{
		'country': 'Taiwan',
		'unicode': 'tw',
		'code': '886'
	},
	{
		'country': 'Tajikistan',
		'unicode': 'tj',
		'code': '992'
	},
	{
		'country': 'Tanzania',
		'unicode': 'tz',
		'code': '255'
	},
	{
		'country': 'Thailand',
		'unicode': 'th',
		'code': '66'
	},
	{
		'country': 'Togo',
		'unicode': 'tg',
		'code': '228'
	},
	{
		'country': 'Tokelau',
		'unicode': 'tk',
		'code': '690'
	},
	{
		'country': 'Tonga',
		'unicode': 'to',
		'code': '676'
	},
	{
		'country': 'Trinidad and Tobago',
		'unicode': 'tt',
		'code': '1-868'
	},
	{
		'country': 'Tunisia',
		'unicode': 'tn',
		'code': '216'
	},
	{
		'country': 'Turkey',
		'unicode': 'tr',
		'code': '90'
	},
	{
		'country': 'Turkmenistan',
		'unicode': 'tm',
		'code': '993'
	},
	{
		'country': 'Turks and Caicos Islands',
		'unicode': 'tc',
		'code': '1-649'
	},
	{
		'country': 'Tuvalu',
		'unicode': 'tv',
		'code': '688'
	},
	{
		'country': 'U.S. Virgin Islands',
		'unicode': 'vi',
		'code': '1-340'
	},
	{
		'country': 'Uganda',
		'unicode': 'ug',
		'code': '256'
	},
	{
		'country': 'Ukraine',
		'unicode': 'ua',
		'code': '380'
	},
	{
		'country': 'United Arab Emirates',
		'unicode': 'ae',
		'code': '971'
	},
	{
		'country': 'United Kingdom',
		'unicode': 'uk',
		'code': '44'
	},
	{
		'country': 'United States',
		'unicode': 'us',
		'code': '1'
	},
	{
		'country': 'Uruguay',
		'unicode': 'uy',
		'code': '598'
	},
	{
		'country': 'Uzbekistan',
		'unicode': 'uz',
		'code': '998'
	},
	{
		'country': 'Vanuatu',
		'unicode': 'vu',
		'code': '678'
	},
	{
		'country': 'Vatican',
		'unicode': 'va',
		'code': '379'
	},
	{
		'country': 'Venezuela',
		'unicode': 've',
		'code': '58'
	},
	{
		'country': 'Vietnam',
		'unicode': 'vn',
		'code': '84'
	},
	{
		'country': 'Wallis and Futuna',
		'unicode': 'wf',
		'code': '681'
	},
	{
		'country': 'Western Sahara',
		'unicode': 'eh',
		'code': '212'
	},
	{
		'country': 'Yemen',
		'unicode': 'ye',
		'code': '967'
	},
	{
		'country': 'Zambia',
		'unicode': 'zm',
		'code': '260'
	},
	{
		'country': 'Zimbabwe',
		'unicode': 'zw',
		'code': '263'
	}
];
