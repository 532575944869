import styled from 'styled-components';
import React, {useEffect, useState} from 'react';
import {datadogRum} from '@datadog/browser-rum';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import {Theme, GlobalStyle, SCREEN_BREAKPOINT_QUERIES, NavigationUtils} from '@imperva/base';
import {NotificationsContainer as NotificationsContainerOrig} from '@imperva/basic-components';
import i18n from './i18n';
import {RootRouter} from './RootRouter';
import {store} from './store';
import {appRoute, history} from './utils';

const DATADOG_SHARED = {
	site: 'datadoghq.com',
	sessionSampleRate: 100,
	sessionReplaySampleRate: 100,
	trackResources: true,
	trackLongTasks: true,
	trackUserInteractions: true,
};

const DATADOG_PRODUCTION = {
	applicationId: '88e91c65-b3e0-4121-b06f-c80d3157a558',
	clientToken: 'pub838476ed905a42dc0ed6e3bbac6d6f76',
	service: 'management.service.imperva.com',
	env: 'prod'
};
const DATADOG_STAGING = {
	applicationId: '9859bb2a-f7d0-49c7-9182-62ce844c71e3',
	clientToken: 'pubfc00461f2aa40a7ff0dc65d44d5fb25b',
	service: 'management.stage.impervaservices.com',
	env: 'stage'
};

datadogRum.init(Object.assign(NavigationUtils.getEnv() === NavigationUtils.ENVIRONMENTS.prod ? DATADOG_PRODUCTION : DATADOG_STAGING, DATADOG_SHARED));


const NotificationsContainer = styled(NotificationsContainerOrig)`
  && {
    right: 10px;
    top: 76px;

    @media ${SCREEN_BREAKPOINT_QUERIES.small} {
      left: 50%;
      transform: translateX(-50%);
      right: 0;
    }
  }
`;

const MsWrapper = styled.div`
	opacity: 0;
	${({fontLoaded}) => fontLoaded && 'opacity: 1; transition: 1s;'}
`;
const checkIsFontLoaded = () => document.fonts.check('1em Inter');

const App = () => {
	const [isFontLoaded, setIsFontLoaded] = useState(checkIsFontLoaded());

	useEffect(() => {
		document.fonts.ready.then(() => {
			setIsFontLoaded(true);
		}).catch(() => {
			setIsFontLoaded(true);
		});

		setTimeout(() => {
			// This is just a backup for the case where the font loading is very slow,
			// we want to wait for max 3s and then display the login page, even wrong fonts
			if(!isFontLoaded) {
				setIsFontLoaded(true);
			}
		}, 2000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	return (
		<Theme isLight>
			<GlobalStyle />
			<I18nextProvider i18n={i18n}>
				<Provider store={store}>
					<MsWrapper fontLoaded={isFontLoaded}>
						<Router
							history={history}
							basename={appRoute}
						>
							<NotificationsContainer draggable={false} />
							<RootRouter />
						</Router>
					</MsWrapper>
				</Provider>
			</I18nextProvider>
		</Theme>
	);
};

export default App;
