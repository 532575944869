import {OktaAuth} from '@okta/okta-auth-js';
import {NavigationUtils} from '@imperva/base';
import {oktaAuthConfig} from '../config';

const oktaAuth = new OktaAuth(oktaAuthConfig);
const currentEnv = NavigationUtils.getEnv();
if (currentEnv === NavigationUtils.ENVIRONMENTS.stage || currentEnv === NavigationUtils.ENVIRONMENTS.dev) {
	window.oktaAuth = oktaAuth;
}
window.oktaAuth = oktaAuth;
export default oktaAuth;
