import {makeAsyncEpic as makeAsyncEpicOrigin} from 'redux-toolbelt-observable';
import * as api from '../utils/api';
import * as actions from './actions';


const makeAsyncEpic = (actionCreator, asyncFunc, options = {cancelPreviousFunctionCalls: true}) => makeAsyncEpicOrigin(actionCreator, asyncFunc, options);

export {observableAsyncActionCallbacks} from '@imperva/form';
export const fetchOktaAuthInfo = makeAsyncEpic(actions.fetchOktaAuthInfo, api.fetchOktaAuthInfo);
export const oktaSigninWithCredentials = makeAsyncEpic(actions.oktaSigninWithCredentials, api.oktaSigninWithCredentials);
export const resetPassword = makeAsyncEpic(actions.resetPassword, api.resetPassword);
export const startAuthTransaction = makeAsyncEpic(actions.startAuthTransaction, api.startAuthTransaction);
export const verifyRecoveryToken = makeAsyncEpic(actions.verifyRecoveryToken, api.verifyRecoveryToken);
export const fetchSendResetPasswordMail = makeAsyncEpic(actions.fetchSendResetPasswordMail, api.fetchSendResetPasswordMail);
export const validateIpAddress = makeAsyncEpic(actions.validateIpAddress,api.validateIpAddress);
export const fetchDefaultTheme = makeAsyncEpic(actions.fetchDefaultTheme,api.fetchDefaultTheme);
export const confirmMailChange = makeAsyncEpic(actions.confirmMailChange, api.confirmMailChange);
export const updateIdpRoles = makeAsyncEpic(actions.updateIdpRoles,api.updateIdpRoles);