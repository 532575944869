// eslint-disable-next-line import/no-duplicates
import {httpGet, httpPost, httpPut, oktaPost} from './http';
// eslint-disable-next-line import/no-duplicates
//import {mockRequestWithDelay} from './http';
import oktaAuth from './OktaAuth';

//export const fetchLoginRolloutType = () => mockRequestWithDelay(100, {loginType: 'OKTA', hybridMode: true, canSwitchToOldLogin: true});
export const fetchOktaAuthInfo = email => oktaAuth.webfinger({resource: `acct:${email}`});
export const oktaSigninWithCredentials = ({username, password}) => oktaAuth.signInWithCredentials({username, password});
export const startAuthTransaction = ({token}) => oktaPost('/api/v1/authn', {token});
export const resetPassword = ({stateToken, newPassword}) => oktaPost('/api/v1/authn/credentials/reset_password', {stateToken, newPassword});
export const verifyRecoveryToken = ({token}) => oktaAuth.verifyRecoveryToken({recoveryToken: token});
export const fetchSendResetPasswordMail = data => httpPost('/api/password/reset-email', data);
export const validateIpAddress = data => httpPost('/api/login/validate', data);
export const fetchDefaultTheme = () => httpGet('/api/theme/default');
export const confirmMailChange = (data) => httpPost('/api/users/email-update', data);
export const updateIdpRoles = (data) => httpPut('/api/login/update-idp-roles', data);

// const themeMock ={ id:'131',accountId:'5859',serviceLink:'link',termsOfUseUrl:'termsOfUseUrl',privacySettingsUrl:'privacySettingsUrl',
//  logoutBannerUrl:'logoutBannerUrl',loginBannerUrl:'loginBannerUrl',releaseNotesUrl:'releaseNotesUrl',releaseNotesText:'releaseNotesText',
//  startFreeTrialLabel:'startFreeTrialLabel',startFreeTrialUrl:'startFreeTrialUrl',path:'path'}
// export const fetchDefaultTheme = () => mockRequestWithDelay(100, themeMock)

