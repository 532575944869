import styled from 'styled-components';
import React, {useCallback, useEffect, useState} from 'react';
import {has} from 'lodash';
import {useTranslation} from 'react-i18next';
import {Skeleton, NotificationController} from '@imperva/basic-components';
import {history} from '../../../../utils';

const Wrapper = styled.div`
	align-items: flex-start;
	display: flex;
    justify-content: space-between;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const QrCode = styled.img`
	border:1px solid #ACB9C5;
	height:140px;
	object-fit:none;
	width:140px;
`;

const Title = styled.div`
	font-size: 0.875rem;
	font-weight: 600;
	padding-bottom: 6px;
`;

const SubTitle = styled.div`
	font-size: 0.75rem;
	font-weight: 400;
	width: 230px;
`;

export const QREnroll = ({factorName, factorData, setTransaction, setPrevFunc}) => {
	const {t} = useTranslation();
	const [qrCode, setQrCode] = useState(null);

	const sendFactorEnroll = useCallback(async () => {
		try {
			const payload = await factorData.enroll();
			setTransaction(payload);
			setPrevFunc(() => payload.prev);
			if (has(payload, ['data','_embedded','factor','_embedded','activation','_links','qrcode','href'])) {
				setQrCode(payload.data._embedded.factor._embedded.activation._links.qrcode.href);
			} else {
				throw new Error('Missing href for QR code');
			}
		} catch (err) {
			NotificationController.error(factorName, {subtitle: t('mfa.enrollForm.qr.error'), container: 'box', position: 'top-center'});
			console.log('Error: ', err);
		}
	}, [factorData, factorName, setPrevFunc, setTransaction, t]);


	useEffect(() => {
		if (!factorData.enroll) {
			history.push('/login');
			return;
		}
		sendFactorEnroll();
	}, [factorData, sendFactorEnroll]);

	return (
		<Wrapper>

			<TextWrapper>
				<Title>
					{t('mfa.enrollForm.qr.scanQr')}
				</Title>
				<SubTitle>
					{t('mfa.enrollForm.qr.helperText', {factorName})}
				</SubTitle>
			</TextWrapper>

			{qrCode ? (
				<QrCode
					id={'qrCode'}
					src={qrCode}
				/>
			) : (
				<Skeleton
					width={'140px'}
					height={'140px'}
				/>
			)}
		</Wrapper>
	);
};