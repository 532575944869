import styled from 'styled-components';
import React, {useCallback, useEffect, useState} from 'react';
import {faInfoCircle, faKey} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useOktaAuth} from '@okta/okta-react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {CancelButton, Link, toast, NotificationController} from '@imperva/basic-components';
import {Form, useSubmitForm, Field, validators} from '@imperva/form';
import {SubmitButton as SubmitButtonOrigin, TextInput, BackLink} from '../../../../shared';
import {OKTA_AUTH_TYPES} from '../../../../shared/constants';
import * as actions from '../../../../store/actions';
import * as selectors from '../../../../store/selectors';
import {history, msPackage} from '../../../../utils';
import {setLastSuccessLoginTimestampCookie} from '../../login/loginUtils';

const {required, email: emailValidator, validateAll} = validators;

export const AuthWithOkta = () => {
	const {data: oktaAuthInfo, loaded: oktaAuthInfoLoaded} = useSelector(selectors.oktaAuthInfo);
	const {oktaAuth} = useOktaAuth();
	const [displayLoader, setDisplayLoader] = useState(false);
	const [oktaErrorMsg, setOktaErrorMsg] = useState(null);
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const submitCb = useCallback((values, meta) => dispatch(actions.oktaSigninWithCredentials(values, {...meta})), [dispatch]);
	const {submitForm} = useSubmitForm(submitCb);
	const loginLinksInfo = oktaAuthInfo?.links?.[0];
	const oktaAuthType = loginLinksInfo?.properties?.['okta:idp:type'];
	const IS_SSO_LOGIN = 'isSsoLogin';
	const [isSso, setIsSso] = useState(localStorage.getItem(IS_SSO_LOGIN) === 'true');

	useEffect(() => {
		if(oktaAuthInfoLoaded && isSso){
			if(oktaAuthType !== OKTA_AUTH_TYPES.okta){
				localStorage.setItem(IS_SSO_LOGIN, 'true');
				setOktaErrorMsg(null);
				window.location.replace(loginLinksInfo?.href);
			}
			else{
				NotificationController.error(t('login.form.ssoNotAllowed'), {subtitle: '', container:'box',position:'top-center'});
				setDisplayLoader(false);
			}
		}
	}, [oktaAuthInfoLoaded, oktaAuthType, t, loginLinksInfo, isSso]);

	const handleFormSuccess = useCallback((values, payload, setSubmitting) => {
		setSubmitting(false);
		localStorage.removeItem(IS_SSO_LOGIN);
		setLastSuccessLoginTimestampCookie();
		if (payload.status === 'PASSWORD_EXPIRED') {
			setDisplayLoader(false);
			dispatch(actions.setPasswordExpiredPayload({...payload}));
			history.push('/password-expired');
		} else if (payload.status === 'MFA_REQUIRED') {
			history.push('/login/mfa');
		} else if (payload.status === 'MFA_ENROLL') {
			history.push('/login/mfa-enroll');
		} else {
			oktaAuth.signInWithRedirect({sessionToken: payload.sessionToken});
		}
	}, [dispatch, oktaAuth]);

	const handleFormFailure = useCallback((payload, setSubmitting) => {
		setSubmitting(false);
		setDisplayLoader(false);
		if (payload.errorCode === 'E0000047') {
			setOktaErrorMsg(t('login.rateLimitError'));
		} else {
			setOktaErrorMsg(payload.errorSummary);
		}
	}, [t]);

	const handleSubmit = useCallback((values, {setSubmitting}) => {
		setOktaErrorMsg(null);
		setDisplayLoader(true);
		submitForm({
			values,
			onSuccess: (payload) => handleFormSuccess(values, payload, setSubmitting),
			onFailure: (payload) => handleFormFailure(payload, setSubmitting),
		});
	}, [handleFormFailure, handleFormSuccess, submitForm]);

	const handleSsoSubmit = useCallback((values, {setSubmitting}) => {
		setSubmitting(false);
		setDisplayLoader(true);
		dispatch(actions.fetchOktaAuthInfo(values?.username, {
			callbacks: {
				success: () => toast.dismiss(),
				failure: () => toast.dismiss()
			},
		}));
	}, [dispatch]);

	const navigateToSso = () => {
		setOktaErrorMsg(null);
		setIsSso(true);
	};

	const backToRegularLogin = useCallback(() => {
		setIsSso(false);
		toast.dismiss();
		dispatch(actions.resetOktaInfo());
	}, [dispatch]);

	return (
		<div>
			{isSso &&
			(
				<BackLink
					text={t('login.back')}
					role='button'
					onClick={backToRegularLogin}
					data-test-label='Back to login'
				/>
			)}
			<Form
				displayLoader={displayLoader}
				initialValues={{username: '', password: ''}}
				showDiscardChangesPopup={true}
				msPackage={msPackage}
				onSubmit={isSso ? handleSsoSubmit : handleSubmit}
				validateOnBlur={false}
			>
				{({isSubmitting, values, errors}) => (
					<>
						<Field
							id='username'
							name='username'
							labelText={t('login.form.email')}
							component={TextInput}
							autoFocus={true}
							aria-required='true'
							autoComplete='email'
							validate={validateAll(required(t('login.form.emailRequired')), emailValidator(t('login.form.emailNotValid')))}
						/>
						{!isSso && (
							<>
								<Field
									id='password'
									name='password'
									type='password'
									labelText={t('login.form.password')}
									component={TextInput}
									aria-required='true'
									autoComplete="current-password"
									validate={validateAll(required(t('login.form.passwordRequired')))}
								/>
								<SubmitButtonOrigin
									type='submit'
									id='signInButton'
									disabled={isSubmitting || errors?.username || errors?.password}
								>{t('login.form.signIn')}</SubmitButtonOrigin>
								{oktaErrorMsg && (
									<OktaError>
										<FontAwesomeIcon icon={faInfoCircle} />
										{oktaErrorMsg}
									</OktaError>
								)}
								<ForgotLink
									role='button'
									onClick={() => history.push('/reset-password-request')}
									data-test-label='go to frogot password'
								>{t('login.siginHelp')}</ForgotLink>
								<SSOButtonWrapper>
									<Separator>
										<SeparatorText>{t('login.or')}</SeparatorText>
									</Separator>
									<SSOCheckButton
										onClick={navigateToSso}
										renderIcon={() => <FontAwesomeIcon icon={faKey} />}
										iconPosition='left'
										id='ssoLoginCheck'
									>
										{t('login.form.signInSso')}
									</SSOCheckButton>
								</SSOButtonWrapper>
							</>
						)}
						{isSso && (
							<SubmitButtonOrigin
								type='submit'
								disabled={isSubmitting || errors?.username}
								id='submitSsoLogin'
							>
								{t('login.form.signInSso')}
							</SubmitButtonOrigin>
						)}
					</>
				)}
			</Form>
		</div>
	);
};

const OktaError = styled.div`
	align-items: center;
	color: ${({theme}) => theme.palette.alert500};
	display: flex;
	font-weight: 600;
	gap: 0.3rem;
	margin-top: 10px;
`;

const SSOButtonWrapper = styled.div`
	margin-top: 1.25rem;
	width: 100%;
`;

const Separator = styled.div`
    align-items: center;
    color: ${({theme}) => theme.palette.grey600};
	display: flex;
    flex-basis: 100%;
    margin: 1.25rem 0;
	width: 100%;
	&:before,
	&:after {
		background: ${({theme}) => theme.palette.grey300};
		content: "";
		flex-grow: 1;
		font-size: 0;
		height: 1px;
		line-height: 0;
	}
`;

const SeparatorText = styled.span`
	font-size: 0.75rem;
	font-weight: 500;
	padding: 0px 0.3rem;
`;

const SSOCheckButton = styled(CancelButton)`
	height: 45px;
	max-width: unset;
	width: 100%;
`;

const ForgotLink = styled(Link)`
	display: flex;
	margin-top: 1.25rem;
`;