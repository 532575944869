import styled from 'styled-components';
import React, {useEffect, useCallback, useState} from 'react';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Notification} from '@imperva/basic-components';
import {BackLink} from '../../../../shared';
import * as selectors from '../../../../store/selectors';
import {history} from '../../../../utils';
import {ExpiredSessionNote} from '../ExpiredSessionNote';
import {Login} from '../Login';
import {FactorItem} from './FactorItem';

const NotifWrapper = styled(Notification)`
	margin-bottom: 20px;
`;

export const EnrollWithOktaMfa = () => {
	const [isExpired, setIsExpired] = useState(false);
	const {t} = useTranslation();
	const {data: signinData} = useSelector(selectors.oktaSigninWithCredentials);
	const enrollFactors = signinData.factors?.filter(item => item.provider !== 'DUO');

	const handleBackToLogin = useCallback(() => {
		history.push('/login');
	}, []);

	useEffect(() => {
		if (isEmpty(signinData))
			history.push('/login');
	}, [signinData]);

	if (isEmpty(signinData)) {
		return null;
	}

	return (
		<Login
			headerText={t('login.mfaHeader')}
		>
			<BackLink
				text={t('login.backToLogin')}
				onClick={handleBackToLogin}
				data-test-label='Back to login'
			/>

			<NotifWrapper
				title={t('mfa.enrollForm.setPreferred')}
				subtitle={t('mfa.enrollForm.adminRequires')}
				kind={'warning'}
				showCloseButton={false}
			/>

			{enrollFactors.map((item, idx) => (
				<FactorItem
					key={idx}
					expiresAt={signinData.expiresAt}
					isExpired={isExpired}
					setIsExpired={setIsExpired}
					enrollMode={true}
					{...item}
				/>
			))}
			{isExpired && <ExpiredSessionNote expirationText={'login.sessionExpired1'} />}
		</Login>
	);
};