import styled from 'styled-components';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {CancelButton} from '@imperva/basic-components';
import {history} from '../../../../utils';
import {isTimePast} from '../../../../utils/helpers';
import {FactorIcon} from './FactorIcon';

const Button = styled(CancelButton)`
	height: 45px;
	margin-bottom: 20px;
	max-width: unset;
	width: 100%;
`;

export const FactorItem = ({id, simpleType, expiresAt, isExpired, setIsExpired, enrollMode, ...props}) => {
	const {t} = useTranslation();

	const handleFactorClick = useCallback(() => {
		const expired = isTimePast(expiresAt);
		if (expired) {
			setIsExpired(true);
			return;
		}
		history.push(`/login/${enrollMode ? 'mfa-enroll' : 'mfa'}/${simpleType}`);
	}, [enrollMode, expiresAt, setIsExpired, simpleType]);

	return (
		<Button
			disabled={isExpired}
			onClick={handleFactorClick}
			renderIcon={() => <FactorIcon type={simpleType} />}
			data-test-label={simpleType}
		>
			<>
				{(enrollMode && t('mfa.factorButtons.configureLogin')) || t('mfa.factorButtons.loginWith')} {t(`mfa.names.${simpleType}`)}
			</>
		</Button>
	);
};