import React from 'react';
import {useTranslation} from 'react-i18next';
import {SuccessMessage, ResultPageWrapper} from '../../../../shared';

export const ResetSuccessfully = () => {
	const {t} = useTranslation();

	return (
		<ResultPageWrapper header={t('resetPassword.successfullyReset.header')}>
			<SuccessMessage>
				{t('resetPassword.successfullyReset.info')}
			</SuccessMessage>
		</ResultPageWrapper>
	);
};