import React from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Loader} from '@imperva/basic-components';
import {Header} from '../../../shared';
import {LoadingPageWrapper as Wrapper} from '../../../shared/LoadingPageWrapper';
import {updatedIdpRoles} from '../../../store/selectors';
import {handleRedirect} from './loginUtils';


export const IdpSyncLoadingPage = ({payload})=>{
	const {t} = useTranslation();
	const {loaded} = useSelector(updatedIdpRoles);
	const {authState} = useOktaAuth();
	const accessToken = authState?.accessToken?.accessToken;
	const refreshToken = authState?.refreshToken?.refreshToken;


	if(loaded){
		handleRedirect(accessToken, refreshToken, payload);
	}

	 return (
		<Wrapper>
			<Header>{t('login.idpSyncLoadingPage.title')}</Header>
			<Loader
				isActive={true}
				customSize={100}
			/>
		</Wrapper>
	);
};