import styled from 'styled-components';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from '@imperva/basic-components';
import {Copyrights, MobileCopyrights} from '../components/Copyrights';
import {history} from '../utils';
import {BackLink} from './BackLink';
import {Header} from './Header';
import {Logo} from './Logo';
import {PaddingTopWrapper} from './PaddingTopWrapper';

const Container = styled(PaddingTopWrapper)`
	@media (min-width: 1024px) {
		margin: 0 auto;
		width: 430px;
	}

	@media screen and (max-width: 1024px) {
    max-width: 70%;
		max-width: 500px;
		margin: 0 auto;
  }
`;

const AnchorLink = styled(Link)`
	margin-top: 20px;
`;

export const ResultPageWrapper = ({children, header, showBackLink = false, className}) => {
	const {t} = useTranslation();

	const handleBackToLogin = useCallback((e) => {
		history.push('/login');
	}, []);

	return (
		<Container className={className}>
			<Logo
				src='/images/logo.png'
				alt='imperva'
			/>
			<Header>{header}</Header>
			{showBackLink && (
				<BackLink
					text={t('login.backToLogin')}
					onClick={handleBackToLogin}
					data-test-label='Back to login'
				/>
			)}
			{children}
			{!showBackLink && (
				<AnchorLink
					onClick={handleBackToLogin}
					data-test-label='go to login'
				>
					{t('login.goToLogin')}
				</AnchorLink>
			)}
			<Copyrights />
			<MobileCopyrights />
		</Container>
	);
};