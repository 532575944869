import Cookies from 'js-cookie';
import {NavigationUtils} from '@imperva/base';
import {setLastSuccessLoginTimestampCookie} from './components/account/login/loginUtils';
import history from './utils/history';

const currentEnv = NavigationUtils.getEnv();
const origin = `${window.location.origin}`;
const clientId = process.env[`REACT_APP_OKTA_CLIENT_ID_${currentEnv}`];
const domain = process.env[`REACT_APP_OKTA_DOMAIN_${currentEnv}`];
const issuer = `https://${domain}/oauth2/default`;
const disableHttpsCheck = (process.env[`REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK_${currentEnv}`] === 'true');
const redirectUri = `${origin}/login/callback`;
const scopes = ['openid', 'profile', 'email', 'groups', 'offline_access'];
const useInteractionCode = (process.env[`REACT_APP_OKTA_USE_INTERACTION_CODE_${currentEnv}`] === 'true');
const  responseType = ['token', 'code'];

const oktaAuthConfig = {
	issuer,
	clientId,
	redirectUri,
	scopes,
	pkce: true,
	disableHttpsCheck,
	useInteractionCode,
	onAuthRequired: () => {
		history.push('/login');
	},
	postLogoutRedirectUri: '/login',
	tokenManager: {
		autoRenew: false
	},
	responseType
};

const getDomain = () => {
	const domain = NavigationUtils.getEnv() === NavigationUtils.ENVIRONMENTS.prod
		? '.imperva.com'
		: '.impervaservices.com';

	return domain;
};

const getLoggedInRedirectUrl = (accessToken, refreshToken, withParams, payload = null) => {
	const REDIRECT_GUID = '263ae8ff-0ec4-40ac-ba4a-ab516333b8f5';
	let url = NavigationUtils.getEnv() === NavigationUtils.ENVIRONMENTS.prod
		? 'https://management.service.imperva.com'
		: 'https://management.stage.impervaservices.com';

	if (withParams) {

		const inThirtySeconds = new Date(new Date().getTime() + 0.5 * 60 * 1000);
		const cookieConfig = {
			secure: true,
			domain: getDomain(),
			expires: inThirtySeconds
		};
		Cookies.set('redirect_key', REDIRECT_GUID, cookieConfig);
		Cookies.set('access_token', accessToken, cookieConfig);
		Cookies.set('refresh_token', refreshToken, cookieConfig);
		setLastSuccessLoginTimestampCookie();
		if (payload) {
			const impToken = payload?.impToken;
			Cookies.set('impToken', impToken, cookieConfig);
		}
	}

	return url;
};

export {oktaAuthConfig, getLoggedInRedirectUrl, getDomain};
