import styled from 'styled-components';
import React from 'react';
import {SCREEN_BREAKPOINT_QUERIES} from '@imperva/base';
import {Link} from '@imperva/basic-components';

const HelpLink = styled(Link)`
	color: ${({theme}) => theme.palette.grey800} !important;
	display: inline-block;
	margin: 28px 0 0;
	text-decoration: underline !important;
	&&{
		&:hover {
			text-decoration: none !important;
		}
	}
`;

const Container = styled.div`
	bottom: 5px;
	font-weight: 500;
	position: fixed;

	@media screen and ${SCREEN_BREAKPOINT_QUERIES.medium} {
		position: relative;
		display: none;
  }
`;

const MobileContainer = styled(Container)`
	display: none;

	@media screen and ${SCREEN_BREAKPOINT_QUERIES.medium} {
		position: relative;
		display: block;
		margin: 0 auto;
		font-size: 0.75rem;
		font-weight: 300;
  }
`;

const YEAR = new Date().getFullYear();

export const Copyrights = () => {
	return (
		<Container
			id='contentInfo'
			role='contentInfo'
		>
			<HelpLink href="https://www.imperva.com/legal/terms-of-use/">Terms of use</HelpLink>
			&nbsp;and&nbsp;
			<HelpLink href="https://www.imperva.com/legal/privacy-policy/">Privacy Policy</HelpLink>
				&nbsp;
			<label>Copyright © {YEAR} Imperva</label>
		</Container>
	);
};

export const MobileCopyrights = () => {
	return (
		<MobileContainer
			id='contentInfo'
			role='contentInfo'
		>
			<HelpLink href="https://www.imperva.com/legal/terms-of-use/">Terms of use</HelpLink>
			&nbsp;and&nbsp;
			<HelpLink href="https://www.imperva.com/legal/privacy-policy/">Privacy Policy</HelpLink>
				&nbsp;
			<label>Copyright © {YEAR} Imperva</label>
		</MobileContainer>
	);
};