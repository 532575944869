import styled from 'styled-components';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {PaddingTopWrapper} from '../../../../shared';

const Flex = styled(PaddingTopWrapper)`
  background-color: ${({theme}) => theme.palette.grey100};
  display: flex;
  flex-wrap: wrap;

`;

const Wrapper = styled.div`
  margin: 0 auto;
`;

const Logo = styled.img `
  margin-bottom: 40px;
  max-height: 40px;
  max-width: 200px;
`;

const Header = styled.div` 
  p {
    color: ${({theme}) => theme.palette.grey800};
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 30px;
  }
`;

export const PasswordPageFrame = ({children, title}) => {
	const {t} = useTranslation();

	return (
		<Flex>
			<Wrapper>
				<Header>
					<Logo src={process.env.PUBLIC_URL + '/images/logo.png'} />
					<p>{t(title)}</p>
				</Header>
				{children}
			</Wrapper>
		</Flex>
	);
};