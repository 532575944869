import React, {useEffect, useState} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {useDispatch} from 'react-redux';
import {JIT_NEW_USER_LOADER_DURATION} from '../../../shared/constants';
import {updateIdpRoles, validateIpAddress} from '../../../store/actions';
import {handleCloseOktaSession} from '../../../utils/navigationUtils';
import {IdpSyncLoadingPage} from './IdpSyncLoadingPage';
import {JitLoadingPage} from './JitLoadingPage';
import {handleLoginValidateFailure, handleRedirect, compareLists} from './loginUtils';

export const LoginSso = () => {
	const {oktaAuth, authState} = useOktaAuth();
	const [hasActiveCall, setHasActiveCall] = useState(false);
	const [isJitProvisioning,setIsJitProvisioning] = useState(false);
	const [isIdpSync, setIsIdpSync] = useState(false);
	const [payload, setPayload] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (hasActiveCall)
			return;

		setHasActiveCall(true);

		const fetchTotkens = async () => {
			try {
				if (authState?.isAuthenticated)
					return;

				const sessionExists = await oktaAuth.session.exists();
				if (!sessionExists)
					return;

				const responseTokens = await oktaAuth.token.getWithoutPrompt();
				oktaAuth.tokenManager.setTokens(responseTokens.tokens);
				const accessToken = responseTokens.tokens?.accessToken?.accessToken;
				const idToken = responseTokens.tokens?.idToken.idToken;
				const idTokenClaims = responseTokens.tokens?.idToken.claims;

				const refreshToken = responseTokens.tokens?.refreshToken?.refreshToken;

				// eslint-disable-next-line camelcase
				dispatch(validateIpAddress({token: idToken, version: 3},{
					oktaAuth,
					callbacks:{
						success:(payload) => {
							// Whether to display the loader while the user is being created during the JIT provisioning, should be 'true' for these users
							if(idTokenClaims?.jit_new_user){
								setIsJitProvisioning(true);
								setTimeout(()=>{handleRedirect(accessToken, refreshToken, payload);},JIT_NEW_USER_LOADER_DURATION);
								// Whether to display a loader when we identify a change in the IDP roles of the user, we redirect to Coligo after the roles have been updated
							} else if(!(idTokenClaims?.groups?.includes('SSO Account admins')) && !compareLists(idTokenClaims?.idp_roles_previous, idTokenClaims?.idp_roles_current)){
								setIsIdpSync(true);
								setPayload(payload);
								dispatch(updateIdpRoles({email:idTokenClaims.email}, {
									callbacks: {
										failure: () => {
											setIsIdpSync(false);
											handleCloseOktaSession(oktaAuth, 'UNKNOWN');
										}
									}
								}));
							}
							else {
								handleRedirect(accessToken, refreshToken, payload);
							}
						},
						failure: async (e) => {
							handleLoginValidateFailure(e, oktaAuth);
						}
					}
				}));

			} catch (e) {
				window.location.replace('/login');
			}
		};

		fetchTotkens();
	}, [authState, hasActiveCall, dispatch, oktaAuth.session, oktaAuth.token, oktaAuth.tokenManager, oktaAuth]);

	if(isJitProvisioning) return  <JitLoadingPage/>;
	else if(isIdpSync) return <IdpSyncLoadingPage payload = {payload}/>;
	else return <></>;
};