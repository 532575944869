import styled from 'styled-components';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {SCREEN_BREAKPOINT_QUERIES} from '@imperva/base';
import {Requirement} from './Requirement';


const Wrapper = styled.div`
  max-width: 334px;
  min-width: 334px;
  padding: 21px 37px 0 40px;
  @media not screen and ${SCREEN_BREAKPOINT_QUERIES.medium} {
    border-left: 1px solid ${({theme}) => theme.palette.grey300};
  }
  span {
    font-weight: 500;
  }

  li {
    padding-top: 10px;
  }
`;

export const Requirements = ({errors = [], displayInvalid, manualErrors, className}) => {
	const {t} = useTranslation();

	const requirements = useMemo(() => [
		{key: 'passwordAssistant.errors.newPassword.min', value: t('passwordAssistant.errors.newPassword.min')},
		{key: 'passwordAssistant.errors.newPassword.uppercase', value: t('passwordAssistant.errors.newPassword.uppercase')},
		{key: 'passwordAssistant.errors.newPassword.lowercase', value: t('passwordAssistant.errors.newPassword.lowercase')},
		{key: 'passwordAssistant.errors.newPassword.number', value: t('passwordAssistant.errors.newPassword.number')},
		{key: 'passwordAssistant.errors.newPassword.special', value: t('passwordAssistant.errors.newPassword.special')},
	], [t]);

	return (
		<Wrapper className={className}>
			<span>{t('passwordAssistant.howToCreatePass')}</span>
			<ul>
				{requirements.map((item) => (
					<li key={item.key}>
						<Requirement
							isValid={errors.includes(item.key) === false}
							displayInvalid={displayInvalid}
							label={item.value}
						/>
					</li>
				)
				)}
				{manualErrors?.map((item, i) => (
					<li key={`manual_error_${i}`}>
						<Requirement
							isValid={false}
							displayInvalid={true}
							label={item.value}
						/>
					</li>
				)
				)}
			</ul>
		</Wrapper>
	);
};
