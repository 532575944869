import styled from 'styled-components';
import React, {useMemo} from 'react';
import {faEnvelope, faComment, faPhone} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import {MFA_TYPES} from '../../../../shared/constants';

const Icon = styled(FontAwesomeIcon)`
	color: ${({theme}) => theme.palette.primary500};
	font-size: 1rem;
	position: relative;
	top: 1px;
`;

const Img = styled.img`
	margin-right: 0.5rem;
	width: 17px;
`;

const SVG_ICONS = {
	[MFA_TYPES.duo]: true,
	[MFA_TYPES.oktaVerify]: true,
	[MFA_TYPES.google]: true,
};

export const FactorIcon = ({type}) => {
	const {t} = useTranslation();
	const isSvg = SVG_ICONS[type];

	const faIcon = useMemo(() => {
		switch(type){
			case MFA_TYPES.email:
			 return faEnvelope;
			case MFA_TYPES.sms:
				return faComment;
			case MFA_TYPES.call:
				return faPhone;
			default:
				return null;
		}
	}	,[type]);

	if (isSvg)
		return (
			<Img
				alt={t(`mfa.names.${type}`)}
				src={`/images/${type}.svg`}
			/>
		);

	return <Icon icon={faIcon} />;
};