import React, {useEffect, useState} from 'react';
import {useOktaSignout} from '../../utils/hooks';

export const Logout = () => {
	const oktaSignout = useOktaSignout();
	const [signingOutInProcess, setSigningOutInProcess] = useState(false);

	useEffect(() => {
		if (signingOutInProcess)
			return;

		setSigningOutInProcess(true);
		oktaSignout();
	}, [oktaSignout, signingOutInProcess]);

	return <>Logging you out in a few moments...</>;
};
