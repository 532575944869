import styled from 'styled-components';
import React from 'react';
import {faCheckCircle as CheckCircle} from '@fortawesome/pro-regular-svg-icons';
import {faCheckCircle as CheckCircleSolid, faTimesCircle as InvalidCircleSolid} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Icon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const CheckIcon = styled(Icon)`  
  filter: opacity(0.3);
`;

const SuccessIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.success400};
  filter: opacity(1);
`;

const ErrorIcon = styled(Icon)`
	color: ${({theme}) => theme.palette.alert500};
`;

export const Requirement = ({label, isValid, displayInvalid}) => {
	if (isValid)
		return <div><SuccessIcon icon={CheckCircleSolid} /><span>{label}</span></div>;

	if (displayInvalid)
		return <div><ErrorIcon icon={InvalidCircleSolid} /><span>{label}</span></div>;

	return <div><CheckIcon icon={CheckCircle} /><span>{label}</span></div>;
};