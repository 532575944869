import styled from 'styled-components';
import React from 'react';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon as FontAwesomeIconOrigin} from '@fortawesome/react-fontawesome';
import {Link} from '@imperva/basic-components';

const FontAwesomeIcon = styled(FontAwesomeIconOrigin)`
	margin-right: 5px;
	position: relative;
`;

const AnchorLink = styled(Link)`
	align-items: center;
	color: ${({theme}) => theme.palette.grey800} !important;
	display: flex;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
`;

export const BackLink = ({text, onClick, className, ...props}) => {
	return (
		<AnchorLink
			onClick={onClick}
			role='button'
			className={className}
			{...props}
		>
			<FontAwesomeIcon icon={faArrowLeft} />
			{text}
		</AnchorLink>
	);
};