import React from 'react';
import {useTranslation} from 'react-i18next';
import {AlertMessage, ResultPageWrapper} from '../../../../shared';

export const ExpiredToken = () => {
	const {t} = useTranslation();

	return (
		<ResultPageWrapper
			header={t('resetPassword.expiredToken.header')}
		>
			<AlertMessage>
				{t('resetPassword.expiredToken.info')}
			</AlertMessage>
		</ResultPageWrapper>
	);
};