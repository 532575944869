import styled from 'styled-components';
import React, {useCallback, useMemo, useEffect, useState} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import Cookies from 'js-cookie';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Link} from '@imperva/basic-components';
import {Form, Field, validators, Checkbox} from '@imperva/form';
import {getDomain} from '../../../../config';
import {BackLink, SubmitButton as SubmitButtonOrigin, TextInput} from '../../../../shared';
import {MFA_TYPES} from '../../../../shared/constants';
import * as actions from '../../../../store/actions';
import * as selectors from '../../../../store/selectors';
import {history} from '../../../../utils';
import {isTimePast} from '../../../../utils/helpers';
import {setLastSuccessLoginTimestampCookie} from '../../login/loginUtils';
import {ExpiredSessionNote} from '../ExpiredSessionNote';
import {Login} from '../Login';

const {required, stringMaxLength, validateAll} = validators;

const SubmitButton = styled(SubmitButtonOrigin)`
	margin-bottom: 20px;
`;

const AnchorLink = styled(Link)`
	color: ${({theme}) => theme.palette.grey800};
	text-decoration: none;
`;

const ResendNote = styled.div`
	margin-bottom: 20px;
`;

const SubTitle = styled.div`
	margin-bottom: 20px;
`;

export const VerifyFactor = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const {simpleType} = useParams();
	const {oktaAuth} = useOktaAuth();
	const [generalExpiredError, setGeneralExpiredError] = useState(null);
	const [allowResendVerifyRequest, setAllowResendVerifyRequest] = useState(false);
	const [verifyRequestLoaded, setVerifyRequestLoaded] = useState(false);
	const [verifyRequestLoading, setVerifyRequestLoading] = useState(false);
	const {data: signinData} = useSelector(selectors.oktaSigninWithCredentials);
	const {data: verifyRequest} = useSelector(selectors.oktaMfaVerificationTransaction);
	const expiresAt = verifyRequest?.expiresAt;
	const isExpired = useMemo(() => isTimePast(expiresAt) || generalExpiredError, [expiresAt, generalExpiredError]);
	const factor = useMemo(() => signinData?.factors?.find(item => item.simpleType === simpleType) || {}, [signinData.factors, simpleType]);
	const resendableFactor = useMemo(() => [MFA_TYPES.email, MFA_TYPES.sms, MFA_TYPES.call].includes(simpleType), [simpleType]);

	const handlePrevStepClick = useCallback(() => {
		if (isExpired) {
			history.push('/login');
			return;
		}
		verifyRequest.prev();
		history.push('/login/mfa');
	}, [isExpired, verifyRequest]);

	const handleSubmit = useCallback(async (values, {setSubmitting, setFieldError}) => {

		if (isExpired) {
			return;
		}

		if (!verifyRequest?.verify) {
			// Not supposed to happen - if it does, we lost current state
			history.push('/login');
		}

		try {
			const payload = await verifyRequest.verify({
				passCode: values.passCode,
				rememberDevice: values.rememberDevice
			});
			const inThreeMinutes = new Date(new Date().getTime() + 3 * 60 * 1000);
			const cookieConfig = {
				secure: true,
				domain: getDomain(),
				expires: inThreeMinutes
			};
			if (payload.status === 'PASSWORD_EXPIRED') {
				const inEightMinutes = new Date(new Date().getTime() + 8 * 60 * 1000);
				cookieConfig.expires = inEightMinutes;
				Cookies.set('login_type', 'mfa', cookieConfig);
				dispatch(actions.setPasswordExpiredPayload({...payload}));
				history.push('/password-expired');
			} else {
				Cookies.set('login_type', 'mfa', cookieConfig);
				setLastSuccessLoginTimestampCookie();
				oktaAuth.signInWithRedirect({sessionToken: payload.sessionToken});
			}
		} catch (e) {
			switch (e.errorCode) {
				case 'E0000068':
					setFieldError('passCode', e.errorSummary);
					break;
				case 'E0000011':
					setGeneralExpiredError(e.errorCode);
					break;
				default:
					setFieldError('passCode', `Unhandled error: ${e.errorSummary} (${e.errorCode}) - please contact Imperva support`);
					break;
			}
			setSubmitting(false);
		}
	}, [dispatch, isExpired, oktaAuth, verifyRequest]);

	const sendFactorVerify = useCallback(async () => {
		if (isExpired) {
			return;
		}
		setVerifyRequestLoading(true);
		setAllowResendVerifyRequest(false);
		try {
			const payload = await factor.verify();
			dispatch(actions.setOktaMfaVerificationTransaction({verificationData: {...payload}}));
		} catch (err) {
			// If user didn't wait for 30 seconds before trying again - we'll get an error.
			// We print to console in case we got any other error
			console.log('Error: ', err);
		}
		setVerifyRequestLoaded(true);
		setVerifyRequestLoading(false);
	}, [dispatch, factor, isExpired]);

	useEffect(() => {
		if (isEmpty(factor)) {
			history.push('/login/mfa');
			return;
		}

		if (verifyRequestLoaded || verifyRequestLoading)
			return;

		sendFactorVerify();
	}, [factor, sendFactorVerify, verifyRequestLoaded, verifyRequestLoading, verifyRequest]);

	useEffect(() => {
		if (allowResendVerifyRequest || !resendableFactor)
			return;

		const timer = setTimeout(() => {
			setAllowResendVerifyRequest(true);
		}, 30000);

		return () => {
			clearTimeout(timer);
		};
	}, [allowResendVerifyRequest, resendableFactor]);

	if (isEmpty(factor))
		return null;

	return (
		<Login
			headerText={t('login.mfaHeader')}
		>
			<div>
				<BackLink
					onClick={handlePrevStepClick}
					text={t('login.back')}
					data-test-label='Previous step'
				/>
			</div>
			<SubTitle>
				{t(`mfa.subTitle.${factor.simpleType}`)}
			</SubTitle>
			<Form
				displayLoader={verifyRequestLoading}
				initialValues={{passCode: '', rememberDevice: false}}
				onSubmit={handleSubmit}
			>
				{({isSubmitting}) => (
					<>
						<Field
							id='passCode'
							name='passCode'
							labelText={t('mfa.verifyForm.passCode')}
							component={TextInput}
							validate={validateAll(required(t('mfa.verifyForm.passCodeRequired')), stringMaxLength(20, 'Code is too long'))}
							disabled={!!isExpired}
						/>
						<Field
							key='rememberDevice'
							id='rememberDevice'
							name='rememberDevice'
							labelText={t('mfa.verifyForm.rememberDevice')}
							component={Checkbox}
						/>
						<SubmitButton
							type='submit'
							disabled={!!(isSubmitting || isExpired)}
						>
							{t('mfa.verifyForm.submit')}
						</SubmitButton>
						{resendableFactor && allowResendVerifyRequest && (
							<ResendNote>
								{t('mfa.verifyForm.resend1')} <AnchorLink onClick={() => sendFactorVerify(true)}>{t('mfa.verifyForm.resend2')}</AnchorLink>
							</ResendNote>
						)}
						{resendableFactor && !allowResendVerifyRequest &&
							<ResendNote>{t('mfa.verifyForm.resendWait')}</ResendNote>
						}
						{isExpired && <ExpiredSessionNote expirationText={'mfa.passcodeExpired1'}/>}
					</>
				)}
			</Form>
		</Login>
	);
};