import styled from 'styled-components';
import React, {useCallback, useMemo, useEffect, useState} from 'react';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useOktaAuth} from '@okta/okta-react';
import Cookies from 'js-cookie';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {PrimaryButton} from '@imperva/basic-components';
import {Form, Field, validators} from '@imperva/form';
import {getDomain} from '../../../../config';
import {BackLink, TextInput} from '../../../../shared';
import {MFA_TYPES} from '../../../../shared/constants';
import * as selectors from '../../../../store/selectors';
import {history} from '../../../../utils';
import {isTimePast} from '../../../../utils/helpers';
import {setLastSuccessLoginTimestampCookie} from '../../login/loginUtils';
import {ExpiredSessionNote} from '../ExpiredSessionNote';
import {Login} from '../Login';
import {PhoneEnroll} from './PhoneEnroll';
import {QREnroll} from './QREnroll';
const {required, stringMaxLength, validateAll} = validators;


const SubTitle = styled.div`
	font-size: 1.125rem;
	font-weight: 500;
	margin-bottom: 20px;
`;

export const FormGroup = styled.div`
  align-items: flex-start;
  display: flex;
`;

export const FieldsGroup = styled.div`
	display: flex;
	margin-right: 15px;
	width: 360px;
`;

export const AlignedToFieldsButtons = styled(PrimaryButton)`
	margin-top: 23px;
`;

const FactorWrapper = styled.div`
	border:1px solid #ACB9C5;
	margin-bottom: 10px;
	padding: 20px;
	width: 510px;
`;

const SuccessWrapper = styled.div`
	align-items: center;
	border:1px solid #ACB9C5;
	display: flex;
	flex-direction: column;
	height: 250px;
	justify-content: center;
	padding: 20px;
	text-align: center;
	width: 500px;
`;

const SuccessTextWrapper = styled.div`
	font-size: 1.125rem;
	font-weight: 700;
	width: 250px;
`;

const CheckmarkIcon = styled(FontAwesomeIcon)`
  color: #2DB07F;
  font-size: 2.5rem;
  padding-bottom: 10px;
`;

const LoginTextWrapper = styled.div`
	font-size: 0.75rem;
	font-weight: 500;
	padding-top: 45px;
`;

export const FactorEnroll = () => {
	const {t} = useTranslation();
	const {simpleType} = useParams();
	const {oktaAuth} = useOktaAuth();
	const {data: signinData} = useSelector(selectors.oktaSigninWithCredentials);
	const factorName = t(`mfa.names.${simpleType}`);
	const [transaction, setTransaction] = useState();
	const expiresAt = transaction?.expiresAt;
	const [generalExpiredError, setGeneralExpiredError] = useState(null);
	const isExpired = useMemo(() => isTimePast(expiresAt) || generalExpiredError, [expiresAt, generalExpiredError]);
	const [activationSuccess, setActivationSuccess] = useState(false);
	const factor = useMemo(() => signinData?.factors?.find(item => item.simpleType === simpleType) || {}, [signinData.factors, simpleType]);
	const [prevFunc, setPrevFunc] = useState(false);
	const isQrCodeFactor = simpleType === MFA_TYPES.google || simpleType === MFA_TYPES.oktaVerify;
	const EnrollComponent = isQrCodeFactor ? QREnroll : PhoneEnroll;
	const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(!isQrCodeFactor);

	const handlePrevStepClick = useCallback(() => {
		if (isExpired) {
			history.push('/login');
			return;
		}
		prevFunc && prevFunc();
		history.push('/login/mfa-enroll');
	}, [isExpired, prevFunc]);


	const handleSubmit = useCallback(async (values, {setSubmitting, setFieldError}) => {
		if (isExpired || !transaction) {
			return;
		}
		try {
			const payload = await transaction.activate({
				passCode: values.passCode,
			});
			const inThreeMinutes = new Date(new Date().getTime() + 3 * 60 * 1000);
			const cookieConfig = {
				secure: true,
				domain: getDomain(),
				expires: inThreeMinutes
			};
			if (payload.status !== 'SUCCESS') {
				setFieldError('passCode', `Unhandled status ${payload.status} - please contact Imperva support`);
			} else {
				// We wish to show the configuration success screen for 5 seconds before logging the user in
				setActivationSuccess(true);
				setLastSuccessLoginTimestampCookie();
				Cookies.set('login_type', 'mfa', cookieConfig);
				setTimeout(() => {
					oktaAuth.signInWithRedirect({sessionToken: payload.sessionToken});
				}, 5000);
			}
		} catch (e) {
			switch (e.errorCode) {
				case 'E0000068':
					setFieldError('passCode', e.errorSummary);
					break;
				case 'E0000011':
					setGeneralExpiredError(e.errorCode);
					break;
				default:
					setFieldError('passCode', `Unhandled error: ${e.errorSummary} (${e.errorCode}) - please contact Imperva support`);
					break;
			}
			setSubmitting(false);
		}
	}, [isExpired, transaction, oktaAuth]);

	useEffect(() => {
		if (isEmpty(factor)) {
			history.push('/login/mfa-enroll');
			return;
		}
	}, [factor]);


	return (
		<Login
			headerText={t('mfa.enrollForm.title', {factorName})}
		>

			{activationSuccess ? (
				<SuccessWrapper>
					<CheckmarkIcon icon={faCheckCircle}/>
					<SuccessTextWrapper>
						{t('mfa.enrollForm.success', {factorName})}
					</SuccessTextWrapper>
					<LoginTextWrapper>
						{t('mfa.enrollForm.loggingIn')}
					</LoginTextWrapper>
				</SuccessWrapper>
			) : (
				<>
					<BackLink
						onClick={handlePrevStepClick}
						text={t('login.back')}
						data-test-label='Previous step'
					/>

					<FactorWrapper>

						<SubTitle>
							{t('mfa.enrollForm.subtitle', {factorName})}
						</SubTitle>

						<EnrollComponent
							factorName={factorName}
							factorData={factor}
							setTransaction={setTransaction}
							setPrevFunc={setPrevFunc}
							setVerifyButtonDisabled={setVerifyButtonDisabled}
						/>

						<Form
							initialValues={{passCode: ''}}
							onSubmit={handleSubmit}
						>
							{({isSubmitting}) => (
								<FormGroup>
									<FieldsGroup>
										<Field
											id='passCode'
											name='passCode'
											labelText={t('mfa.verifyForm.passCode')}
											component={TextInput}
											validate={validateAll(required(t('mfa.verifyForm.passCodeRequired')), stringMaxLength(20, 'Code is too long'))}
											disabled={!!isExpired}
										/>
									</FieldsGroup>
									<AlignedToFieldsButtons
										type='submit'
										disabled={verifyButtonDisabled || !!(isSubmitting || isExpired)}
									>
										{t('mfa.enrollForm.verify')}
									</AlignedToFieldsButtons>
								</FormGroup>
							)}
						</Form>
					</FactorWrapper>

					{isExpired && <ExpiredSessionNote expirationText={'mfa.passcodeExpired1'}/>}
				</>
			)}
		</Login>
	);
};
