import i18next from 'i18next';
import Cookies from 'js-cookie';
import {isEqual} from 'lodash';
import {getDomain, getLoggedInRedirectUrl} from '../../../config';
import {LAST_SUCCESS_LOGIN_TIMESTAMP} from '../../../shared/constants';
import {handleCloseOktaSession} from '../../../utils/navigationUtils';

export const setLastSuccessLoginTimestampCookie = () => {
	const cookieConfig = {
		domain: getDomain(),
		expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) // 24 hours
	};
	Cookies.set(LAST_SUCCESS_LOGIN_TIMESTAMP, Date.now().toString(), cookieConfig);
};

export const handleRedirect = (accessToken, refreshToken, payload = null) => {
	const inSixtySeconds = new Date(new Date().getTime() + 60 * 1000);
	const cookieConfig = {
		secure: true,
		domain: getDomain(),
		expires: inSixtySeconds
	};
	Cookies.set('login_type', 'sso', cookieConfig);
	window.location.replace(getLoggedInRedirectUrl(accessToken, refreshToken, true, payload));
};


export const handleLoginValidateFailure = (e, oktaAuth) => {
	if (e.response.status === 403) {
		handleCloseOktaSession(oktaAuth, e.response.data.title);
	} else {
		handleCloseOktaSession(oktaAuth, 'UNKNOWN');
	}
};

export const getLoginValidationFailureBannerData = (error) => {

	switch (error) {
		case 'FORBIDDEN_IP':
			return {title: i18next.t('login.ipAddresses.title'), subTitle: i18next.t('login.ipAddresses.subtitle')};
		case 'IDP_MISMATCH':
			return {title: i18next.t('login.idpMismatch.title'), subTitle: i18next.t('login.idpMismatch.subtitle')};
		case 'SSO_ONLY':
			return {title: i18next.t('login.ssoOnly.title'), subTitle: i18next.t('login.ssoOnly.subtitle')};
		case 'API_ONLY':
			return {title: i18next.t('login.apiOnly.title'), subTitle: i18next.t('login.apiOnly.subtitle')};
		default:
			return {title: i18next.t('login.internalError.title'), subTitle: i18next.t('login.internalError.subtitle')};
	}

};


// Compares two lists to determine if they contain the same elements, regardless of order
export const compareLists = (list1, list2) => {
	if (!list1 && !list2) {
		return true;
	}

	if (!list1 || !list2) {
		return false;
	}

	if (list1.length !== list2.length) {
		return false;
	}

	list1.sort();
	list2.sort();
	return isEqual(list1, list2);
};
