import styled from 'styled-components';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {SCREEN_BREAKPOINT_QUERIES} from '@imperva/base';
import {
	PrimaryButton,
} from '@imperva/basic-components';
import {Form, Field, TextInput} from '@imperva/form';
import * as selectors from '../../../../store/selectors';
import {history} from '../../../../utils';
import {isTimePast} from '../../../../utils/helpers';
import {ExpiredSessionNote} from '../../login/ExpiredSessionNote';
import {setLastSuccessLoginTimestampCookie} from '../../login/loginUtils';
import {changePasswordSchema, Requirements, PasswordPageFrame} from '../shared';
import {parseOktaPasswordErrorResponse} from '../shared/utils';

const PasswordForm = styled.div`
  min-width: 430px;
  padding-right: 40px;
`;

const Flex = styled.div`
  display: flex;

  @media ${SCREEN_BREAKPOINT_QUERIES.medium} {
    flex-direction: column;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 40px 0 20px 20px;

`;

export const PasswordExpiredPage = () => {
	const passwordExpiredPayload = useSelector(selectors.passwordExpiredPayload);
	const {oktaAuth} = useOktaAuth();
	const [displayLoader, setDisplayLoader] = useState(false);
	const [isExpired, setIsExpired] = useState(false);
	const [oktaErrMsg, setOktaErrMsg] = useState(null);
	const {t} = useTranslation();

	const handleSigInFailure = useCallback((setSubmitting, errorObj) => {
		const oktaError = parseOktaPasswordErrorResponse(errorObj);
		if (oktaError) {
			setOktaErrMsg(t(oktaError));
		}
		setSubmitting(false);
		setDisplayLoader(false);
	}, [t]);

	const handleSubmit = useCallback(async (values, {setSubmitting}) => {
		setOktaErrMsg(null);
		const expired = isTimePast(passwordExpiredPayload.expiresAt);
		if (expired || isExpired) {
			setIsExpired(true);
			return;
		}
		setDisplayLoader(true);
		const {oldPassword, newPassword} = values;
		try {
			const result = await passwordExpiredPayload.changePassword({oldPassword, newPassword});

			const sessionToken = result.sessionToken;
			setSubmitting(false);
			setLastSuccessLoginTimestampCookie();
			oktaAuth.signInWithRedirect({sessionToken});
		} catch (e) {
			handleSigInFailure(setSubmitting, e);
		}
	}, [handleSigInFailure, isExpired, oktaAuth, passwordExpiredPayload]);

	const validate = useCallback((values) => {
		return changePasswordSchema.validate(values, {abortEarly: false}).then(data => {
			return {};
		}).catch(err => {
			const errors = {};
			err.inner.forEach(error => {
				if (!errors[error.path]) errors[error.path] = [];
				errors[error.path].push(error.message);
			});
			return errors;
		});
	}, []);

	const initialValues = useMemo(() => {
		return {
			oldPassword: '',
			newPassword: '',
			confirmNewPassword: ''
		};
	}, []);

	useEffect(() => {
		if (isEmpty(passwordExpiredPayload))
			history.push('/login');
	}, [passwordExpiredPayload]);

	return  (
		<PasswordPageFrame title={t('passwordExpired.title')}>
			<Form
				displayLoader={displayLoader}
				validationSchema={changePasswordSchema}
				validateOnMount={true}
				validate={validate}
				initialValues={initialValues}
				onSubmit={handleSubmit}
			>
				{({isSubmitting, dirty, errors, touched}) => {
					return (
						<Flex>
							<PasswordForm>
								<Field
									id='oldPassword'
									name='oldPassword'
									type='password'
									labelText={t('passwordAssistant.oldPassword')}
									component={TextInput}
									generalErrorMessage={t('passwordAssistant.errors.oldPasswordRequired')}
									disabled={isExpired}
								/>
								<Field
									id='newPassword'
									name='newPassword'
									type='password'
									labelText={t('passwordAssistant.newPassword')}
									component={TextInput}
									generalErrorMessage={t('passwordAssistant.errors.newPasswordCheck')}
									disabled={isExpired}
								/>
								<Field
									id='confirmNewPassword'
									name='confirmNewPassword'
									type='password'
									labelText={t('passwordAssistant.confirmNewPassword')}
									component={TextInput}
									inputWrapperCustomStyle={'margin-bottom: 0px;'}
									generalErrorMessage={t('passwordAssistant.errors.confirmNewPassword.match')}
									disabled={isExpired}
								/>
								<Actions>
									<PrimaryButton
										type="submit"
										disabled={isSubmitting || !dirty || isExpired}
									>{t('passwordExpired.form.saveButton')}</PrimaryButton>
								</Actions>
								{isExpired && <ExpiredSessionNote expirationText={'login.sessionExpired1'} />}
							</PasswordForm>
							<Requirements
								errors={errors.newPassword || []}
								displayInvalid={!!(touched.newPassword && errors.newPassword)}
								manualErrors={oktaErrMsg && [{value: oktaErrMsg}]}
							/>
						</Flex>
					);
				}}
			</Form>
		</PasswordPageFrame>
	);
};