
import moment from 'moment';
import {NavigationUtils} from '@imperva/base';

export const getMyUrl = () => {
	let url = '';
	switch(NavigationUtils.getEnv()) {
		case NavigationUtils.ENVIRONMENTS.dev:
			url = 'http://localhost:9000';
			break;
		case NavigationUtils.ENVIRONMENTS.prod:
			url = 'https://my.imperva.com';
			break;
		case NavigationUtils.ENVIRONMENTS.stage:
		default:
			url = 'https://my.impervaservices.com';
			break;
	}

	return url;
};

export const getOldLoginUrl = () => {
	let url = getMyUrl();
	url = `${url}/admin/login`;
	return url;
};

export const getOldResetPassUrl = (email) => {
	return `${getMyUrl()}/login/forgot?email=${encodeURIComponent(email)}`;
};

export const isTimePast = time => time && moment(time).diff(moment.now()) < 0;

export const delay = ms => {
	let timeout, promise;
	promise = new Promise(res => {
		timeout = setTimeout(res, ms);
	});

	return {
		promise,
		cancel: () => clearTimeout(timeout)
	};
};