import {useOktaAuth} from '@okta/okta-react';

export const useOktaSignout = () => {
	const {oktaAuth} = useOktaAuth();
	const signout = async () => {
		oktaAuth.tokenManager.clear();
		await oktaAuth.signOut();
	};

	return signout;
};