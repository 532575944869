import styled from 'styled-components';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Form, Field, validators} from '@imperva/form';
import {Logo, Header, SubmitButton, TextInput, PaddingTopWrapper, BackLink, SuccessMessage} from '../../../../shared';
import * as actions from '../../../../store/actions';
import {history} from '../../../../utils';
import {msPackage} from '../../../../utils/coligo';
import {Copyrights, MobileCopyrights} from '../../../Copyrights';

const Container = styled(PaddingTopWrapper)`
	@media (min-width: 1024px) {
		margin: 0 auto;
		width: 430px;
	}
	@media screen and (max-width: 1024px) {
    width: 70%;
		max-width: 500px;
		margin: 0 auto;
  }
`;

const {required, email, validateAll} = validators;

export const ResetPasswordRequest = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [resetRequestSent, setResetRequestSent] = useState(false);
	const [username, setUsername] = useState('');

	const [submitting] = useState(false);

	const handleFormSuccess = useCallback((values, payload, setSubmitting) => {
		setSubmitting(false);
		setResetRequestSent(true);
	}, []);

	const handleFormFailure = useCallback((setSubmitting) => {
		setSubmitting(false);
		setResetRequestSent(true);
	}, []);

	const resetPassword = useCallback((formValues, setSubmitting) => {
		dispatch(actions.fetchSendResetPasswordMail({email: formValues.username}, {
			callbacks: {
				success: (payload) => handleFormSuccess(formValues, payload, setSubmitting),
				failure: () => handleFormFailure(setSubmitting)
			}
		}));
	}, [dispatch, handleFormFailure, handleFormSuccess]);

	const handleSubmit = useCallback((values, {setSubmitting}) => {
		setUsername(values.username);
		resetPassword(values, setSubmitting);
	},
	[resetPassword]
	);

	const handleBackToLogin = useCallback(() => {
		history.push('/login');
	}, []);

	return (
		<Container>
			<Logo
				src='/images/logo.png'
				alt='imperva'
			/>
			<Header href='#'>{t('resetPasswordRequest.header')}</Header>
			<BackLink
				text={t('login.backToLogin')}
				role='button'
				onClick={() => handleBackToLogin()}
				data-test-label='Back to login'
			/>
			{!resetRequestSent && (
				<Form
					displayLoader={submitting}
					initialValues={{username: ''}}
					showDiscardChangesPopup={true}
					msPackage={msPackage}
					onSubmit={handleSubmit}
				>
					{({isSubmitting}) => (
						<>
							<Field
								id='username'
								name='username'
								labelText={t('resetPasswordRequest.form.email')}
								component={TextInput}
								validate={validateAll(required(t('resetPasswordRequest.form.emailRequired')), email(t('resetPasswordRequest.form.emailNotValid')))}
							/>
							<SubmitButton
								id='temp-click'
								type='submit'
								disabled={isSubmitting}
							>{t('resetPasswordRequest.form.resetButton')}</SubmitButton>
						</>
					)}
				</Form>
			)}
			{resetRequestSent && (
				<SuccessMessage>
					{t('resetPasswordRequest.succesMessage1', {username: username})}
					<br /><br />
					{t('resetPasswordRequest.succesMessage2')}
				</SuccessMessage>
			)}
			<Copyrights />
			<MobileCopyrights />
		</Container>
	);
};