import styled from 'styled-components';
import React, {useEffect, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from '@imperva/basic-components';
import {BackLink} from '../../../../shared';
import {fetchDefaultTheme} from '../../../../store/actions';
import {Login} from '../Login';
import {AuthWithOkta} from './AuthWithOkta';

const RegisterContent = styled.div`
	margin-top: 2rem;
	p {
		font-size: 14px;
		font-weight: normal;
	}
	a {
		color: ${({theme}) => theme.palette.primary500};
	}
`;

// const oldForgotPassUrl = `${getMyUrl()}/login/forgot`;

export const AuthFlow = () => {
	const dispatch = useDispatch();

	// This state flag is needed cause there are calbacks which need to be executed after the main submit of step1.
	// If the render will remove Step1 component before the calbacks are executed,
	// the app will show warning of possible memory leak
	const [step1Done, setStep1Done] = useState(false);
	const {t} = useTranslation();

	const onPrevStepClick = useCallback(() => {
		setStep1Done(false);
	}, []);

	useEffect(()=>{
		dispatch(fetchDefaultTheme());
	},[dispatch]);


	return (
		<Login
			headerText={t('login.header')}
		>
			<>
				{step1Done && (
					<div>
						<BackLink
							onClick={onPrevStepClick}
							text={t('login.back')}
							data-test-label='Previous step'
						/>
					</div>
				)}
				<AuthWithOkta />
				<RegisterContent>
					<p>
						{t('login.register.part1')}
						<FooterLink
							target='_blank'
							href="https://www.imperva.com/request-demo/"
						>{t('login.register.part2')}
						</FooterLink>
						{t('login.register.part3')}
						<FooterLink
							target='_blank'
							href="https://www.imperva.com/free-trial/"
						>{t('login.register.part4')}
						</FooterLink>
					</p>
				</RegisterContent>
			</>
		</Login>
	);
};

const FooterLink = styled(Link)`
	padding: 0 0.2rem;
`;
