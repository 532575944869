import {combineReducers} from 'redux';
import {combineEpics} from 'redux-observable';
import {ColigoStoreManager} from '@imperva/base';
import * as epics from './epics';
import * as reducers from './reducers';
const {coligoStoreUserReducer, coligoStoreUiReducer} = ColigoStoreManager;

export const rootReducer = combineReducers({
	...reducers,
	user: coligoStoreUserReducer,
	uiColigo: coligoStoreUiReducer
});

export const rootEpic = combineEpics(
	...Object.values(epics),
);