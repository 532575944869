import {MFA_TYPES} from '../shared/constants';

const getSimpleFactorType = factor => {
	let simpleType = MFA_TYPES[factor.factorType.toLowerCase()];
	if (simpleType)
		return simpleType;

	if (factor.factorType === 'token:software:totp') {
		// eslint-disable-next-line default-case
		switch (factor.provider) {
			case 'GOOGLE':
				simpleType = MFA_TYPES.google;
				break;
			case 'OKTA':
				simpleType = MFA_TYPES.oktaVerify;
				break;
		}
	}
	return simpleType;
};

export const addSimpleTypeToFactor = factor => {
	const simpleType = getSimpleFactorType(factor);

	return {
		...factor,
		simpleType
	};
};