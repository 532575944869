import React, {useCallback, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Loader} from '@imperva/basic-components';
import {SuccessMessage, AlertMessage, ResultPageWrapper} from '../../../shared';
import * as actions from '../../../store/actions';
import {history} from '../../../utils';
import {useQueryParams, useOktaSignout} from '../../../utils/hooks';


export const EmailChangeConfirmation = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [apiCallExecuted, setApiCallExecuted] = useState(false);
	const [submitting, setSubmitting] = useState(true);
	const [hasError, setHasError] = useState(false);
	const {sl} = useQueryParams({decode: false});
	const oktaSignout = useOktaSignout();

	const handleCallFininshed = useCallback(() => {
		setSubmitting(false);
	}, []);

	const handleSuccess = useCallback(() => {
		oktaSignout();
		handleCallFininshed();
	}, [handleCallFininshed, oktaSignout]);

	const handleFailure = useCallback(() => {
		setHasError(true);
		handleCallFininshed();
	}, [handleCallFininshed]);

	const startEmailConfirmationFlow = useCallback(() => {
		setSubmitting(true);
		dispatch(actions.confirmMailChange({sl}, {
			callbacks: {
				success: () =>	handleSuccess(),
				failure: () => handleFailure()
			}
		}));
	}, [dispatch, sl, handleSuccess, handleFailure]);

	useEffect(() => {
		if (!sl) {
			history.push('/login');
			return;
		}

		if (apiCallExecuted)
			return;

		setApiCallExecuted(true);
		startEmailConfirmationFlow();
	}, [apiCallExecuted, sl, startEmailConfirmationFlow]);

	const header = submitting ?
		t('emailChangeConfirmation.loadingHeader') :
		hasError ? t('emailChangeConfirmation.failureHeader') : t('emailChangeConfirmation.successHeader');

	if (!sl)
		return null;

	return (
		<ResultPageWrapper
			header={header}
		>
			<Loader
				isActive={submitting}
				customSize={80}
				withOverlay={true}
			/>
			{!submitting && (
				<>
					{!hasError && (
						<SuccessMessage>
							{t('emailChangeConfirmation.succesMessage1')}
							<br />
							{t('emailChangeConfirmation.succesMessage2')}
						</SuccessMessage>
					)}
					{hasError && (
						<AlertMessage>
							{t('emailChangeConfirmation.failureMessage1')}
							<br />
							{t('emailChangeConfirmation.failureMessage2')}
						</AlertMessage>
					)}
				</>
			)}
		</ResultPageWrapper>
	);
};