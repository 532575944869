import React from 'react';
import {LoginCallback, Security} from '@okta/okta-react';
import i18next from 'i18next';
import {Redirect, Route, Switch} from 'react-router-dom';
import {NotificationController} from '@imperva/basic-components';
import {EmailChangeConfirmation} from './components/account/email/EmailChangeConfirmation';
import {AuthFlow} from './components/account/login/authentication/AuthFlow';
import {LoginSso} from './components/account/login/LoginSso';
import {EnrollWithOktaMfa} from './components/account/login/mfa/EnrollWithOktaMfa';
import {FactorEnroll} from './components/account/login/mfa/FactorEnroll';
import {LoginWithOktaMfa} from './components/account/login/mfa/LoginWithOktaMfa';
import {VerifyFactor} from './components/account/login/mfa/VerifyFactor';
import {Logout} from './components/account/Logout';
import {PasswordExpiredPage} from './components/account/password/expired/PasswordExpiredPage';
import {ResetPasswordPage} from './components/account/password/reset/ResetPasswordPage';
import {ResetPasswordRequest} from './components/account/password/reset/ResetPasswordRequest';
import {RESET_PASSWORD_TYPES} from './shared/constants';
import {appRoute, history, oktaAuth} from './utils';

export const RootRouter = () => {

	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		history.push('/login?withParams=1');
	};

	const onError = ({error}) => {
		NotificationController.error(i18next.t('login.internalError.title'), {subtitle: i18next.t('login.internalError.subtitle'), container: 'box', position: 'top-center'});
		return <Redirect to={`${appRoute}/login`} />;
	};

	return (
		<Security
			oktaAuth={oktaAuth}
			restoreOriginalUri={restoreOriginalUri}
		>
			<Switch>
				<Route
					path="/:base*/email-confirmation"
					component={EmailChangeConfirmation}
				/>
				<Route
					path="/:base*/password-expired"
					component={PasswordExpiredPage}
				/>
				<Route
					exact
					path="/:base*/reset-password-request"
					component={ResetPasswordRequest}
				/>
				<Route
					exact
					path="/:base*/reset-password"
					render={() => <ResetPasswordPage resetPassType={RESET_PASSWORD_TYPES.forgotPassword} />}
				/>
				<Route
					exact
					path="/:base*/activate"
					render={() => <ResetPasswordPage resetPassType={RESET_PASSWORD_TYPES.activateAccount} />}
				/>
				<Route
					exact
					path="/:base*/logout"
					component={Logout}
				/>
				<Route
					exact
					path="/:base*/login"
					component={AuthFlow}
				/>
				<Route
					exact
					path="/:base*/login/mfa/:simpleType"
					component={VerifyFactor}
				/>
				<Route
					exact
					path="/:base*/login/mfa"
					component={LoginWithOktaMfa}
				/>
				<Route
					exact
					path="/:base*/login/mfa-enroll/:simpleType"
					component={FactorEnroll}
				/>
				<Route
					exact
					path="/:base*/login/mfa-enroll"
					component={EnrollWithOktaMfa}
				/>
				<Route
					path="/:base*/login/callback"
					component={ (props) => (
						<LoginCallback
							{...props}
							onAuthResume={restoreOriginalUri}
							errorComponent={onError}
						/>
					) }
				/>
				<Route
					path="/:base*/login/sso"
					component={LoginSso}
				/>
				<Redirect to={`${appRoute}/login`} />
			</Switch>
		</Security>
	);
};

