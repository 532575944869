/* eslint-disable */
import {first, last} from 'lodash';
import queryString from 'query-string';
import {appName, isInColigo} from './coligo';
import {NotificationController} from "@imperva/basic-components";
import {getLoginValidationFailureBannerData} from "../components/account/login/loginUtils";

export const getNavigationUrlPrefix = () => {
	if (isInColigo) {
		if (process.env.REACT_APP_LOCAL_IN_STAGE)
			return 'https://authentication-management.stage.impervaservices.com';

		let baseUrl = System.resolve(`@imperva/${appName}`);
		if (baseUrl) {
			const baseUrlPath = baseUrl.split("/");
			// Remove last segment, if pointing to a js bundle file
			if (last(baseUrlPath).indexOf(".js") !== -1) baseUrlPath.pop();
			// Remove protocol only if exist
			if (first(baseUrlPath).indexOf("http") !== -1) {
				baseUrlPath.shift();
				baseUrlPath.shift();
			}
			return `//${baseUrlPath.join("/")}`;
		}
	}
	return '';
};

export const getQueryString = ({url = null, options}) => {
  const qs = queryString.parse(url || window.location.search, options)
  return qs;
};

export const clearQueryStringParams = () => {
	window.history.pushState({}, document.title, window.location.pathname);
};


export const handleCloseOktaSession = (oktaAuth, errorType) => {
	oktaAuth.closeSession();
	if (window.location.pathname.includes('sso')) {
		localStorage.setItem('errorType', btoa(errorType));
		window.location.replace('/login');
	} else {
		const loginValidationFailureBannerData = getLoginValidationFailureBannerData(errorType);
		const title = loginValidationFailureBannerData.title;
		const subtitle = loginValidationFailureBannerData.subTitle;
		NotificationController.error(title, {subtitle, container:'box',position:'top-center'})
		clearQueryStringParams();
	}
};


export const getValueFromQueryString = (value, options) => {
	const qs = getQueryString({options});
	if (qs) {
		return qs[value];
	}
	return '';
};