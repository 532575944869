
import styled from 'styled-components';
import {SCREEN_BREAKPOINT_QUERIES} from '@imperva/base';

export const Header = styled.h1`
	font-size: 1.75rem;
	font-weight: 800;
	margin: 30px 0 20px;
	
    @media screen and ${SCREEN_BREAKPOINT_QUERIES.small} {
		font-size: 1.25rem;
		margin-top: 20px;
    }
`;