import styled from 'styled-components';
import React from 'react';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {FlagService} from '@imperva/basic-components';
import {Dropdown} from '@imperva/dropdown';
import {useFormikContext} from '@imperva/form';
import {countryCodes} from './CountryCodes';

const LabelWrapper = styled.div`
display:flex;
justify-content:flex-start;
`;

const LabelText = styled.span`
padding-left:5px;
`;

const customStyles = {
	menu: (provided) => ({
		...provided,
		width: '300px',
	}),
	option: (provided) => ({
		...provided,
		fontSize: '14px',
		width: '300px',
	}),
	control: (provided) => ({
		...provided,
		width: '100px',
		border:'1px solid rgb(128,139,149)'
	})
};

const CountryCodeDropdownWrapper = styled.div`
  align-items:flex-end;
  display:flex;
  margin-bottom:${({isError})=> isError ? '19' : '0'}px;
`;

export const CountryCodeDropdown = ({setCountryCode, countryCode}) => {
	const {t} = useTranslation();
	const {errors} = useFormikContext();

	return 	(
		<CountryCodeDropdownWrapper isError={!isEmpty(errors)}>
			<Dropdown
				isSearchable={true}
				customStyles={customStyles}
				id="countryCode"
				label={t('mfa.enrollForm.phone.phoneNumber')}
				name='countryCode'
				onChange={(value)=> {
					value.value = value.value.split(' ')[0]; // Remove country name from value
					setCountryCode(value);
				}}
				value={{label:countryCode.value, value:countryCode.value}}
				options={mapCountryCodesToDropdownOptions(countryCodes)}
				isModal={false}
			/>
		</CountryCodeDropdownWrapper>
	);
};

const CountryCodeLabel = ({country, unicode, code}) => (
	<LabelWrapper>
		<FlagService
			country={unicode}
			format={'unicode'}
			size={27}
			fallbackComponent={<div/>}
		/>
		<LabelText>{`${country} (+${code})`}</LabelText>
	</LabelWrapper>
);


const mapCountryCodesToDropdownOptions = (countryCodes) =>
	countryCodes.map(({country, unicode, code}) => ({
		value: '+' + code + ' ' + country,
		label:
			<CountryCodeLabel
				country={country}
				code={code}
				unicode={unicode}
			/>
	}));
