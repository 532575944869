import {composeReducers, makeAsyncReducer as makeAsyncReducerOrigin, makeReducer} from 'redux-toolbelt';
import * as actions from './actions';
import {addSimpleTypeToFactor} from './dataMapHelper';

export const makeAsyncReducer = (actions, options) => makeAsyncReducerOrigin(actions, {
	shouldDestroyDataOnError: false,
	shouldDestroyData: false,
	...options
});

const loginInitialState = {
	data: {},
	loading: false,
	loaded: false,
};
export const oktaAuthInfo = composeReducers(
	makeAsyncReducer(actions.fetchOktaAuthInfo, {
		defaultData: {},
	}),
	(state = loginInitialState, {type, payload}) => {
		switch(type){
			case actions.resetOktaInfo.TYPE:
				return {}	;
			default:
				return state;
		}
	}
);

export const oktaSigninWithCredentials = makeAsyncReducer(actions.oktaSigninWithCredentials, {
	defaultData: {},
	dataGetter: (state, action) => {
		const payload = action.payload;
		const {factors, profile, data} = payload;
		const {expiresAt, factorResult, status, stateToken} = data;
		const custiomizedFactors = factors?.map(addSimpleTypeToFactor);
		return {
			expiresAt,
			factorResult,
			status,
			stateToken,
			factors: custiomizedFactors,
			profile
		};
	}
});

const oktaMfaVerificationTransactionInitialState = {
	data: {}
};

export const oktaMfaVerificationTransaction = (state = oktaMfaVerificationTransactionInitialState, {type, payload}) => {
	switch (type) {
		case actions.setOktaMfaVerificationTransaction.TYPE:
			return {
				...state,
				data: {
					...payload.verificationData
				},
			};
		default:
			return state;
	}
};


export const recoveryTokenInfo = makeAsyncReducer(actions.verifyRecoveryToken, {
	defaultData: {}
});

export const passwordExpiredPayload = makeReducer(actions.setPasswordExpiredPayload, {
	defaultState: {},
});


export const updatedIdpRoles = makeAsyncReducer(actions.updateIdpRoles, {
	defaultState: {},
});


export const theme = makeAsyncReducer(actions.fetchDefaultTheme, {
	defaultData: {},
});

const uiInitialState = {
	displayUserActionsView: false,
	selectedUser: null,
};

export const ui = (uiState = uiInitialState, {type, payload}) => {
	switch (type) {
		case actions.setUI.TYPE:
			return {
				...uiState,
				...payload,
			};
		default:
			return uiState;
	}
};
