import axios from 'axios';
import {http, NavigationUtils} from '@imperva/base';
import {getNavigationUrlPrefix} from './navigationUtils';
window.httpAxios = http;
export const sleep = (ms) => {
	return new Promise(resolve => setTimeout(resolve, ms));
};

// A global instance which will always use same request structure

http.defaults.baseURL = getNavigationUrlPrefix();
http.defaults.withCredentials = true;
http.defaults.headers = {
	'Content-Type': 'application/json',
	'Accept': 'application/json'
};

const currentEnv = NavigationUtils.getEnv();
const domain = process.env[`REACT_APP_OKTA_DOMAIN_${currentEnv}`] || 'okta.stage.impervaservices.com';

export const oktaHttp = axios.create({
	baseURL: `https://${domain}`,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	}
});

// Wrapper for api calls, that will handle data or error in unified approach
const wrapper = (func, url, setCancel, ...args) => {
	// Add cancellation func if needed
	if (setCancel) {
		args[0].cancelToken = new http.CancelToken(function executor(c) {
			if (setCancel) {
				setCancel(c);
			}
		});
	}

	return new Promise((resolve, reject) => {
		func.apply(null, [url, ...args]).then(response => {
			if (response.status === 200) {
				resolve(response.data);
			} else {
				reject(response.data);
			}
		}).catch(error => {
			console.error(`Error in fetching data: ${error}`);
			reject(error);
		});
	});
};

export const httpGet = (url, params = null, setCancel = null) => wrapper(http.get, url, setCancel,{params});
export const httpPost = (url, payload = null, params = null) => wrapper(http.post, url, null, payload,{params});
export const httpPut = (url, payload = null, params = null) => wrapper(http.put, url, null, payload, {params});
export const httpDelete = (url, params = null) => wrapper(http.delete, url, null,{params});

export const oktaGet = (url, params = null, setCancel = null) => wrapper(oktaHttp.get, url, setCancel, {params});
export const oktaPost = (url, payload = null, params = null) => wrapper(oktaHttp.post, url, null, payload, {params});

const sleeper = (ms) => {
	return (x) => {
		return new Promise(resolve => setTimeout(() => resolve(x), ms));
	};
};

export const mockRequestWithDelay = (delayMs, data) => Promise.resolve().then(sleeper(delayMs)).then(() => (data));
