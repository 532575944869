export const MFA_TYPES = {
	duo: 'duo',
	email: 'email',
	google: 'google',
	sms: 'sms',
	oktaVerify: 'oktaVerify',
	call: 'call'
};

export const LOGIN_TYPES = {
	my: 'MY',
	okta: 'OKTA',
};

export const OKTA_AUTH_TYPES = {
	okta: 'OKTA',
	saml2: 'SAML2'
};

export const RESET_PASSWORD_TYPES = {
	forgotPassword: 1,
	activateAccount: 2,
};

// period to wait before attempting to log in (user is being created meanwhile)
export const JIT_NEW_USER_LOADER_DURATION = 1000 * 11;

export const LAST_SUCCESS_LOGIN_TIMESTAMP = 'lastSuccessLoginTimestamp';