export const parseOktaPasswordErrorResponse = errorObj => {
	const {errorCode, errorCauses, errorSummary} = errorObj || {};
	const firstError = errorCauses?.[0]?.errorSummary;
	if (errorCode === 'E0000080') {
		return (firstError ?? errorSummary);
	}
	if (errorCode === 'E0000014') {
		if(firstError.indexOf('no parts of your username') !== -1) {
			return 'passwordAssistant.errors.newPassword.noIncludesUseranme';
		}

		return firstError;
	}
	if (errorCode === 'E0000035') {
		return (errorSummary);
	}
	return null;
};