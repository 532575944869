export const ui = (state) => state.ui;

export const oktaAuthInfo = (state) => state.oktaAuthInfo;

export const recoveryTokenInfo = (state) => state.recoveryTokenInfo;

export const oktaSigninWithCredentials = (state) => state.oktaSigninWithCredentials;

export const oktaMfaVerificationTransaction = (state) => state.oktaMfaVerificationTransaction;

export const passwordExpiredPayload = (state) => state.passwordExpiredPayload;

export const theme = (state) => state.theme;

export const updatedIdpRoles = (state) => state.updatedIdpRoles;