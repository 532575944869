import styled from 'styled-components';
import React, {useEffect, useState} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {SCREEN_BREAKPOINT_QUERIES} from '@imperva/base';
import {NotificationController} from '@imperva/basic-components';
import {getLoggedInRedirectUrl} from '../../../config';
import {Logo, Header, PaddingTopWrapper} from '../../../shared';
import {validateIpAddress} from '../../../store/actions';
import {getValueFromQueryString} from '../../../utils/navigationUtils';
import {Copyrights, MobileCopyrights} from '../../Copyrights';
import {getLoginValidationFailureBannerData, handleLoginValidateFailure} from './loginUtils';
import {MarketContent} from './MarketContent';

const Container = styled.div`
  margin: auto 0;
  min-width: 375px;
  padding: 15px 0;
  @media screen and ${SCREEN_BREAKPOINT_QUERIES.medium} {
	padding-bottom: 40px;
  }
  width: 100%;
`;

const Flex = styled.div`
  background-color: #fff;
  display: flex;
  @media screen and ${SCREEN_BREAKPOINT_QUERIES.medium} {
  	flex-direction: column;
  }
  margin: -15px 0;
`;

const LoginWrapper = styled(PaddingTopWrapper)`
  @media not screen and ${SCREEN_BREAKPOINT_QUERIES.medium} {
  	min-height: 100vh;
  }
  height: 100% !important;
  margin: 0 auto;
  max-width: 500px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  width: 460px;
  
  @media screen and ${SCREEN_BREAKPOINT_QUERIES.small} {
  	max-width: 375px;
	width: 350px;
	padding-left: 10px;
	padding-right: 10px;
  }
`;

export const Login = ({children, headerText}) => {
	const {oktaAuth, authState} = useOktaAuth();
	const redirectWithParams = !!getValueFromQueryString('withParams');
	const errorType = localStorage.getItem('errorType');
	const dispatch = useDispatch();
	const [ipCheck, setIpCheck] = useState(false);
	const {t} = useTranslation();

	useEffect(() => {

		if (errorType) {
			const loginValidationFailureBannerData = getLoginValidationFailureBannerData(atob(errorType));
			const title = loginValidationFailureBannerData.title;
			const subtitle = loginValidationFailureBannerData.subTitle;
			NotificationController.error(title, {subtitle, container: 'box', position: 'top-center'});
			localStorage.removeItem('errorType');
		}

	}, [errorType]);

	useEffect(() => {

		if (!oktaAuth) {
			return;
		}

		if (!authState?.isAuthenticated) {
			return;
		}

		const accessToken = authState?.accessToken?.accessToken;
		const idToken = authState?.idToken?.idToken;
		const refreshToken = authState?.refreshToken?.refreshToken;

		if (!accessToken) {
			return;
		}

		if (!refreshToken) {
			return;
		}

		//This check is needed cause no need to redirect to Coligo, if the user try to access the login page once he is signed in. The API-GW is responsible for this check.
		if (!redirectWithParams) {
			return;
		}

		setIpCheck(true);

		// eslint-disable-next-line camelcase
		dispatch(validateIpAddress({token: idToken, version: 3},{
			oktaAuth,
			callbacks: {
				success: (payload) => {
					window.location.replace(getLoggedInRedirectUrl(accessToken, refreshToken, redirectWithParams, payload));
				},
				failure: async (e) => {
					setIpCheck(false);
					handleLoginValidateFailure(e, oktaAuth);
				}
			}
		}));
	}, [authState, oktaAuth, redirectWithParams, dispatch, t]);

	if (authState?.isPending || ipCheck)
		return null;

	return (
		<Container>
			<Flex>
				<LoginWrapper
					id='main'
					role='main'
				>
					<Logo
						src='/images/logo.png'
						alt='imperva'
					/>
					<Header
						id='cloud-Security_console_login_title'
						href='#'
					>{headerText}</Header>
					<>{children}</>
					<Copyrights />
				</LoginWrapper>
				<MarketContent />
				<MobileCopyrights />
			</Flex>
		</Container>
	);
};
