import styled from 'styled-components';
import React from 'react';
import {useSelector} from 'react-redux';
import {SCREEN_BREAKPOINT_QUERIES} from '@imperva/base';
import {PaddingTopWrapper} from '../../../shared';
import * as selectors from '../../../store/selectors';

const Wrapper = styled.div`
  background-color: #F8F5F9;
  flex: 0 0 50%;
  max-width: 50%;

  @media screen and ${SCREEN_BREAKPOINT_QUERIES.medium} {
    max-width: 100%;
		flex: 1;
		background-color: unset;
  }
`;

const BannerContainer = styled(PaddingTopWrapper)`
  height: 100% !important;
  margin: 0 auto;
  max-width: 360px;
  
  @media not screen and ${SCREEN_BREAKPOINT_QUERIES.medium} {
  	min-height: 100vh;
  }
  
  padding-bottom: 15px;
  width: 100% !important;
  img {
	border-radius: 8px;
  }
`;


const Resources = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  height: 60px;
  justify-content: center;
  margin: 15px 0px;
  width: 100%;

  @media not screen and ${SCREEN_BREAKPOINT_QUERIES.medium} {
    font-size: 1rem;
    line-height: 30px;
    min-width: 400px;
  }

  a {
    color: #285ae6;
    font-size: 1rem;
    line-height: 30px;
  }
`;

export const MarketContent = () => {
	const {data: theme} = useSelector(selectors.theme);

	return (
		<Wrapper
			id='complementary'
			role='complementary'
		>
			<BannerContainer>
				<a
					alt='Client-Side Protection banner'
					href={theme.loginBannerUrl ? theme.loginBannerUrl : 'https://www.imperva.com/products/client-side-protection/'}
				>
					<img
						src={theme.path ?  theme.path + '/loginBanner.png' : '/images/csp_login.png' }
						alt="Imperva"
					/>
				</a>
				<Resources>
					<a href={theme.releaseNotesUrl ?  theme.releaseNotesUrl : 'https://docs.imperva.com/bundle/cloud-application-security/page/release-notes/2020-07-05.htm'}>Latest
            release notes</a>
				</Resources>
			</BannerContainer>
		</Wrapper>
	);
};