import styled from 'styled-components';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {SCREEN_BREAKPOINT_QUERIES} from '@imperva/base';
import {PrimaryButton} from '@imperva/basic-components';
import {Form, Field, TextInput} from '@imperva/form';
import {RESET_PASSWORD_TYPES} from '../../../../shared/constants';
import * as actions from '../../../../store/actions';
import * as selectors from '../../../../store/selectors';
import {useQueryParams} from '../../../../utils/hooks/useQueryParams';
import {setLastSuccessLoginTimestampCookie} from '../../login/loginUtils';
import {resetPasswordSchema, Requirements, PasswordPageFrame} from '../shared';
import {parseOktaPasswordErrorResponse} from '../shared/utils';
import {ExpiredToken} from './ExpiredToken';
import {ResetSuccessfully} from './ResetSuccessfully';

const PasswordForm = styled.div`
  min-width: 430px;
  padding-right: 40px;
`;

const Flex = styled.div`
  display: flex;

  @media ${SCREEN_BREAKPOINT_QUERIES.medium} {
    flex-direction: column;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 40px 0 20px 20px;
`;

export const ResetPasswordPage = ({resetPassType}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const {oktaAuth} = useOktaAuth();
	const [displayExpiredPage, setDisplayExpiredPage] = useState(false);
	const [displaySuccessPage, setDisplaySuccessPage] = useState(false);
	const [oktaErrMsg, setOktaErrMsg] = useState(null);
	const {o_token: token} = useQueryParams();
	const {loaded: verifyTokenLoaded, error: verifyTokenError} = useSelector(selectors.recoveryTokenInfo);
	const [submitting, setSubmitting] = useState(false);

	const handleFormFlowFailure = useCallback((setSubmittingFormik, payload) => {
		setSubmitting(false);
		setSubmittingFormik(false);

		const oktaError = parseOktaPasswordErrorResponse(payload.response?.data);
		if (oktaError) {
			setOktaErrMsg(t(oktaError));
		} else {
			setDisplayExpiredPage(true);
		}
	}, [t]);

	const handleResetPasswordSuccess = useCallback((payload) => {
		if (resetPassType === RESET_PASSWORD_TYPES.activateAccount) {
			setLastSuccessLoginTimestampCookie();
			oktaAuth.signInWithRedirect({sessionToken: payload.sessionToken});
			return;
		}

		setDisplaySuccessPage(true);
	}, [oktaAuth, resetPassType]);

	const resetPassword = useCallback((formValues, stateToken, setSubmittingFormik) => {
		dispatch(actions.resetPassword({stateToken, newPassword: formValues.newPassword}, {
			callbacks: {
				success: (payload) => handleResetPasswordSuccess(payload, setSubmittingFormik),
				failure: (payload) => handleFormFlowFailure(setSubmittingFormik, payload),
			}
		}));
	}, [dispatch, handleFormFlowFailure, handleResetPasswordSuccess]);

	const handleSubmit = useCallback((formValues, {setSubmitting: setSubmittingFormik}) => {
		setOktaErrMsg(null);
		setSubmitting(true);
		dispatch(actions.verifyRecoveryToken({token}, {
			callbacks: {
				success: (payload) => resetPassword(formValues, payload.data.stateToken, setSubmittingFormik),
				failure: (payload) => handleFormFlowFailure(setSubmittingFormik, payload),
			}
		}));
	}, [dispatch, handleFormFlowFailure, resetPassword, token]);

	const validate = useCallback((values) => {
		return resetPasswordSchema.validate(values, {abortEarly: false}).then(data => {
			return {};
		}).catch(err => {
			const errors = {};
			err.inner.forEach(error => {
				if (!errors[error.path]) errors[error.path] = [];
				errors[error.path].push(error.message);
			});
			return errors;
		});
	}, []);

	const initialValues = useMemo(() => {
		return {
			newPassword: '',
			confirmNewPassword: ''
		};
	}, []);

	useEffect(() => {
		if (verifyTokenLoaded)
			return;

		dispatch(actions.verifyRecoveryToken({token}));
	}, [dispatch, token, verifyTokenLoaded]);

	useEffect(() => {
		if (!isEmpty(verifyTokenError)) {
			setDisplayExpiredPage(true);
		}
	}, [token, verifyTokenError]);

	if (displayExpiredPage)
		return <ExpiredToken />;

	if (displaySuccessPage)
		return <ResetSuccessfully />;

	return (
		<PasswordPageFrame title='resetPassword.title'>
			<Form
				validationSchema={resetPasswordSchema}
				validateOnMount={true}
				validate={validate}
				initialValues={initialValues}
				onSubmit={handleSubmit}
				displayLoader={submitting || !verifyTokenLoaded}
			>
				{({isSubmitting, dirty, errors, touched}) => {
					return (
						<Flex>
							<PasswordForm>
								<Field
									id='newPassword'
									name='newPassword'
									type='password'
									labelText={t('passwordAssistant.newPassword')}
									component={TextInput}
									generalErrorMessage={t('passwordAssistant.errors.newPasswordCheck')}
								/>
								<Field
									id='confirmNewPassword'
									name='confirmNewPassword'
									type='password'
									labelText={t('passwordAssistant.confirmNewPassword')}
									component={TextInput}
									inputWrapperCustomStyle={'margin-bottom: 0px;'}
									generalErrorMessage={t('passwordAssistant.errors.confirmNewPassword.match')}
								/>
								<Actions>
									<PrimaryButton
										type="submit"
										disabled={isSubmitting || !dirty}
									>{t('resetPassword.form.saveButton')}</PrimaryButton>
								</Actions>
							</PasswordForm>
							<Requirements
								errors={errors.newPassword || []}
								displayInvalid={!!(touched.newPassword && errors.newPassword)}
								manualErrors={oktaErrMsg && [{value: oktaErrMsg}]}
							/>
						</Flex>
					);
				}}
			</Form>
		</PasswordPageFrame>
	);
};