import styled from 'styled-components';
import React, {useCallback} from 'react';
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from '@imperva/basic-components';
import * as actions from '../../../store/actions';
import {history} from '../../../utils';

const Icon = styled(FontAwesomeIcon)`
	position: relative;
	top: 1px;
`;

const AnchorLink = styled(Link)`
	color: ${({theme}) => theme.palette.alert500} !important;
`;

const Wrapper = styled.div`
	color: ${({theme}) => theme.palette.alert500};
`;

export const ExpiredSessionNote = ({expirationText, className}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();

	const handleBackToLogin = useCallback(() => {
		dispatch(actions.setOktaMfaVerificationTransaction({verificationData: {}}));
		history.push('/login');
	}, [dispatch]);

	return (
		<Wrapper className={className}>
			<Icon icon={faExclamationCircle} /> {t(expirationText)} <AnchorLink onClick={handleBackToLogin}>{t('mfa.sessionExpired2')}</AnchorLink> {t('mfa.sessionExpired3')}
		</Wrapper>
	);
};