import React from 'react';
import {useTranslation} from 'react-i18next';
import {Loader} from '@imperva/basic-components';
import {Header} from '../../../shared';
import {LoadingPageWrapper as Wrapper} from '../../../shared/LoadingPageWrapper';


export const JitLoadingPage = ()=>{
	const {t} = useTranslation();

	return (
		<Wrapper>
			<Header>{t('login.jitLoadingPage.title')}</Header>
			<Header>{t('login.jitLoadingPage.subtitle')}</Header>
			<Loader
				isActive={true}
				customSize={100}
			/>
		</Wrapper>
	);
};