import styled from 'styled-components';
import {PaddingTopWrapper} from './PaddingTopWrapper';

export const LoadingPageWrapper = styled(PaddingTopWrapper)`
align-items:center;
background-color: ${({theme}) => theme.palette.grey100};
display:flex;
flex-direction:column;
justify-content:center;
`;

