import {createStore, applyMiddleware, compose} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createEpicMiddleware} from 'redux-observable';
import {ColigoStoreManager} from '@imperva/base';
import {msPackage, appName} from '../utils/coligo';
import {rootEpic, rootReducer} from './roots';

const configStore = () => {
	const {connector} = ColigoStoreManager;
	const epicMiddleware = createEpicMiddleware();
	const middlewares = [epicMiddleware];
	const composeEnhancers = process.env.NODE_ENV !== 'development' ? compose : composeWithDevTools({name: appName});
	const store = createStore(
		rootReducer,
		composeEnhancers(applyMiddleware(...middlewares)));

	epicMiddleware.run(rootEpic);

	connector(store.dispatch, msPackage);
	return store;
};

export const store = configStore();