import {makeAsyncActionCreator, makeActionCreator} from 'redux-toolbelt';

export const fetchOktaAuthInfo = makeAsyncActionCreator('FETCH_OKTA_AUTH_INFO');

export const oktaSigninWithCredentials = makeAsyncActionCreator('OKTA_SIGNIN_WITH_CREDENTIALS');

export const setOktaMfaVerificationTransaction = makeActionCreator('OKTA_MFA_VERIFICATION_TRANSACTION');

export const sendChangePassword = makeAsyncActionCreator('SEND_CHANGE_PASSWORD');

export const startAuthTransaction = makeAsyncActionCreator('START_AUTH_TRANSACTION');

export const resetPassword = makeAsyncActionCreator('RESET_PASSWORD');

export const setUI = makeActionCreator('SET_UI');

export const setPasswordExpiredPayload = makeActionCreator('SET_PASSWORD_EXPIRED_PAYLOAD');

export const setMfaEnrollTransaction = makeActionCreator('SET_MFA_ENROLL_TRANSACTION');

export const verifyRecoveryToken = makeAsyncActionCreator('VERIFY_RECOVERY_TOKEN');

export const fetchSendResetPasswordMail = makeAsyncActionCreator('FETCH_SEND_RESET_PASSWORD_MAIL');

export const validateIpAddress = makeAsyncActionCreator('VALIDATE_IP_ADDRESS');

export const fetchDefaultTheme = makeAsyncActionCreator('FETCH_DEFAULT_THEME');

export const confirmMailChange = makeAsyncActionCreator('CONFIRM_MAIL_CHANGE');

export const updateIdpRoles = makeAsyncActionCreator('UPDATE_IDP_ROLES');

export const resetOktaInfo = makeActionCreator('RESET_OKTA_INFO');